import axios from "axios";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message,  Spin,} from 'antd';
import React, { useState } from 'react';
import Nav from "./navbar";
import Footer from "./footer";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URL, uploads } from '../config/config';
import { styled } from '@mui/joy/styles';
import Input from '@mui/joy/Input';

const StyledInput = styled('input')({
  border: 'none', // remove the native input border
  minWidth: 0, // remove the native input width
  outline: 0, // remove the native input outline
  padding: 0, // remove the native input padding
  paddingTop: '1em',
  flex: 1,
  color: 'inherit',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  textOverflow: 'ellipsis',
  '&::placeholder': {
    opacity: 0,
    transition: '0.1s ease-out',
  },
  '&:focus::placeholder': {
    opacity: 1,
  },
  '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
    top: '0.5rem',
    fontSize: '0.75rem',
  },
  '&:focus ~ label': {
    color: 'var(--Input-focusedHighlight)',
  },
  '&:-webkit-autofill': {
    alignSelf: 'stretch', // to fill the height of the root slot
  },
  '&:-webkit-autofill:not(* + &)': {
    marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
    paddingInlineStart: 'var(--Input-paddingInline)',
    borderTopLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    borderBottomLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
  },
});
// import { Flex, } from 'antd';
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};



const Upload_image = () => {
  const [country, setCountry] = useState('1');
  const onChange0 = (value) => {
    setCountry(value);
    setNumbers(null);
  };
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const local = localStorage.getItem('token');
  const params = useParams();
  const id = params.id;
  const [images_name,setImages_name] = useState(null);
  const [images_name1,setImages_name1] = useState(null);
  const [images_name2,setImages_name2] = useState(null);
  const [images_name3,setImages_name3] = useState(null);
  const [images_name4,setImages_name4] = useState(null);
  const [images_name5,setImages_name5] = useState(null);
  const [images_name6,setImages_name6] = useState(null);
  const { TextArea } = Input;

  const ad_id = params.id;
  // const userid = localStorage.getItem('token');
  const [location, setLocation] = useState();
  const [descriptions, setDescriptions] = useState();
  const [categories, setCategories] = useState();
  const [podcategories, setPodategories] = useState();
  const [images, setImages] = useState(null);
  const [price, setPrice] = useState();
  const [numbers, setNumbers] = useState();
  const [currency, setCurrency] = useState();
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [image5, setImage5] = useState();
  const [image6, setImage6] = useState();
  const [recommend, setRecommend] = useState();
  const [metro, setMetro] = useState();
  const [city, setCity] = useState();
  let [imagess,setImagess] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);
  const [product, setProduct] = useState(null);
  const [subSubkvartiry, setSubkvartiry] = useState();
  const [subCategories, setSubcategories] = useState();
  const [subCategories2, setSubcategories2] = useState(null);
  const [subCategories3, setSubcategories3] = useState();
  const [subCategories4, setSubcategories4] = useState();
  const [subCategories5, setSubcategories5] = useState();
  const [subCategories6, setSubcategories6] = useState();
  const [subSrok, setSubSrok] = useState();
  const [subKomnat, setSubKomnat] = useState(null);
  const [subRoom, setSubroom] = useState();
  const [subFloor, setSubFloor] = useState();
  const [subPeriod, setSubPeriod] = useState();
  const [SubBuy_sell, setSubBuy_sell] = useState();
  const [SubType_kv, setSubType_kv] = useState();
  const [mark, setMark] = useState();
  const [model, setModel] = useState();
  const [generation, setGeneration] = useState();
  const [serie, setSerie] = useState();
  const [modification, setModification] = useState();
  const [year, setYear] = useState();
  const [year_value, setYear_value] = useState();
  const [buy_sell, setBuy_sell] = useState();
  const [komnat, setKomnat] = useState();
  const [floor, setFloor] = useState();
  const [period, setPeriod] = useState();
  const [srok, setSrok] = useState();
  const [room, setRoom] = useState();
  const [type_kv, setType_kv] = useState();
  
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: 'none',
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  

  
  
  
  const [usertoken, setUsertoken] = useState(null);
  const useridtoken = async () => {
    if(localStorage.getItem('token') != null){
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        // 
        if (data.data.status == 200) {
            
            setUsertoken(data.data.users5[0].id);
            fetchProducts();
            fetchProduct();
        }if(localStorage.getItem('g') != null){
            const post = {
                id: localStorage.getItem('g'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            // 
            if (data.data.status == 200) {
                // 
                setUsertoken(data.data.users5[0].id);
                fetchProducts();
                fetchProduct();
            }
    }
        } 
  }
  
  
  
  const [products, setProducts] = useState(null);
  const fetchProducts = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getmetro'
    });
    if (data.data.status == 200) {
      setProducts(data.data.getmetro);
      
    } else {
      
    }
  }
  
  const fetchProduct = async () => {
    const post = {
      id: id,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL+'/post'
    });
    
    if (data.data.status == 200) {
      setImages_name(data.data.post[0].images);
      if(data.data.post[0].categories == 2){
        fetchCategorymark(data.data.post[0].categories);
        fetchYear(data.data.post[0].generation);
        fetchCategory(data.data.post[0].categories);
        fetchModel(data.data.post[0].mark);
        fetchGeneration(data.data.post[0].models);
        fetchSerie(data.data.post[0].generation);
        fetchModification(data.data.post[0].serie);
       
      }
      if(data.data.post[0].podcategories == 18){
        fetchBuy_sell(data.data.post[0].podcategories);
        
      }
      if(data.data.post[0].buy_sell == 31 ){
        fetchPeriod(data.data.post[0].buy_sell);
      }
      if(data.data.post[0].buy_sell == 32){
        fetchType_kv(data.data.post[0].buy_sell);
      }
      if(data.data.post[0].period == 41 || data.data.post[0].period == 40 ){
        fetchSrok(data.data.post[0].period);
      }
      if(data.data.post[0].podcategories >= 25 && data.data.post[0].podcategories <= 30){
        fetchkvartiry(data.data.post[0].podcategories);
        fetchKomnat(2500);
        fetchFloor(33);
      }
        fetchCategory(data.data.post[0].categories);
      // fetchkvartiry(data.data.post[0].podcategories);
      setImages_name(data.data.post[0].images);
      setFloor(data.data.post[0].floor);
      setBuy_sell(data.data.post[0].buy_sell);
      setPeriod(data.data.post[0].period);
      setCategories(data.data.post[0].categories);
      setModification(data.data.post[0].modification);
      setMark(data.data.post[0].mark);
      setModel(data.data.post[0].models);
      setGeneration(data.data.post[0].generation);
      setSerie(data.data.post[0].serie);
      setYear_value(data.data.post[0].year);
      setProduct(data.data.post);
      setLocation(data.data.post[0].location);
      setCity(data.data.post[0].city);
      setDescriptions(data.data.post[0].descriptions);
      setCategories(data.data.post[0].categories);
      setPodategories(data.data.post[0].podcategories);
      setNumbers(data.data.post[0].numbers);
      setCurrency(data.data.post[0].currency);
      setPrice(data.data.post[0].price);
      setImages(data.data.post[0].images);
      setImage1( data.data.post[0].image1);
      setImage2(data.data.post[0].image2);
      setImage3(data.data.post[0].image3);
      setImage4(data.data.post[0].image4);
      setImage5(data.data.post[0].image5);
      setImage6(data.data.post[0].image6);
      setFiles([...files,uploads + data.data.post[0].images,uploads +data.data.post[0].image1,uploads +data.data.post[0].image2,uploads +data.data.post[0].image3,uploads +data.data.post[0].image4,data.data.post[0].image5,uploads +data.data.post[0].image6,])
      
    
      setFileList(...fileList,
      [
        {
          uid: 's',
          id: data.data.post[0].id,
          name: data.data.post[0].images,
          status: 'done',
          url: data.data.post[0].images != null || data.data.post[0].images != undefined ? uploads + data.data.post[0].images : uploads + 'no_image3.png',
        },
      {
        uid: '2',
        id: data.data.post[0].id,
        name: data.data.post[0].image1,
        status: 'done',
        url: data.data.post[0].image1 != null ? uploads + data.data.post[0].image1 : uploads + 'no_image3.png',
      },
      {
        uid: '3',
        id: data.data.post[0].id,
        name: data.data.post[0].image2,
        status: 'done',
        url: data.data.post[0].image2 != null ? uploads + data.data.post[0].image2 : uploads + 'no_image3.png',
      }
      ,
      {
        uid: '4',
        id: data.data.post[0].id,
        name: data.data.post[0].image3,
        status: 'done',
        url: data.data.post[0].image3 != null ? uploads + data.data.post[0].image3 : uploads + 'no_image3.png',
      }
      ,
      {
        uid: '5',
        id: data.data.post[0].id,
        name: data.data.post[0].image4,
        status: 'done',
        url: data.data.post[0].image4 != null ? uploads + data.data.post[0].image4 : uploads + 'no_image3.png',
      }
      ,
      {
        uid: '6',
        id: data.data.post[0].id,
        name: data.data.post[0].image5,
        status: 'done',
        url: data.data.post[0].image5 != null ? uploads + data.data.post[0].image5 : uploads + 'no_image3.png',
      }
      ,
      {
        uid: '7',
        id: data.data.post[0].id,
        name: data.data.post[0].image6,
        status: 'done',
        url: data.data.post[0].image6 != null ? uploads + data.data.post[0].image6 : uploads + 'no_image3.png',
      }
      ]
      )
      
      setRecommend(data.data.post[0].recommend);
      setMetro(data.data.post[0].metro);
      
      
    } else {
      
      
    }
  }
  const onchanges = (value) => {
    setImages(value);
    
      up_avatar();
  };
 
  const update = async () => {
        window.location.href='/userinfo/'+ 3 
  }

const onRemove = async (e)=>{
  const post = {
    id: id,
    image_name: e.name,
    uid: e.uid
  };
  const data = await axios({
    method: 'delete',
    params: post,
    url: API_URL+'/update1',
  });
  
}
const [products2, setProducts2] = useState(null);
  const fetchProducts2 = async () => {
    const post = {
      id: '0',
      type: 2
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL + '/getcategory'
    });
    if (data.data.status == 200) {
      setProducts2(data.data.getcategory);
      
    } else{
      
      
    }
  }
  const fetchCategory = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubcategories(data.data.getcategory);
    } else {
    }
  }
  const fetchkvartiry = async (id) => {
    const params = {
      id: id 
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubkvartiry(data.data.getcategory);
      
    } else {
    }
  }
  const fetchBuy_sell = async (id) => {
    const params = {
      id: id + '00'
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubBuy_sell(data.data.getcategory);
    } else {
    }
  }
  const fetchKomnat = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubKomnat(data.data.getcategory);
    } else {
    }
  }
  const fetchFloor = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubFloor(data.data.getcategory);
    } else {

    }
  }
  const fetchPeriod = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubPeriod(data.data.getcategory);

    } else {
      // setCustom3(false);
    }
  }
  const fetchType_kv = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubType_kv(data.data.getcategory);

    } else {
      // setCustom3(false);
    }
  }
  
  const fetchSrok = async (id) => {

    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubSrok(data.data.getcategory);

    } else {
      // setCustom3(false);
    }
  }
  const fetchCategorymark = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory4',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubcategories2(data.data.getcategory4);
    } else {
    }
  }
  const fetchModel = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory5',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubcategories3(data.data.getcategory5);
    } else {
    }
  }
  // generation 
  const fetchGeneration = async (id) => {
    const params = {
      id: id
    }
      const data = await axios({
        method: 'get',
        url: API_URL + '/getcategory6',
        params: params
      });
      
      if (data.data.status == 200) {
        setSubcategories4(data.data.getcategory6);
      } else {
      }
  }
   // serie 
   const fetchSerie = async (id) => {
    const params = {
      id: id
    }
    
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory7',
      params: params
    })
    
    if (data.data.status == 200) {
      setSubcategories5(data.data.getcategory7);
    } else {
    }
  }
  const fetchModification = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory8',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubcategories6(data.data.getcategory8);
    } else {
    }
  }
  const fetchYear = async (id) => {
    const params = {
      id: id
    }
      const data = await axios({
        method: 'get',
        url: API_URL + '/year3',
        params: params
      });
      
      if (data.data.status == 200) {
        setYear(data.data.year3);
      } else {
      }
  }
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImage1, setSelectedImage1] = useState();
  const [selectedImage2, setSelectedImage2] = useState();
  const [selectedImage3, setSelectedImage3] = useState();
  const [selectedImage4, setSelectedImage4] = useState();
  const [selectedImage5, setSelectedImage5] = useState();
  const [selectedImage6, setSelectedImage6] = useState();

  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };
  const imageChange1 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage1(e.target.files[0]);
    }
  };
  const imageChange2 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage2(e.target.files[0]);
    }
  };
  const imageChange3 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage3(e.target.files[0]);
    }
  };
  const imageChange4 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage4(e.target.files[0]);
    }
  };
  const imageChange5 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage5(e.target.files[0]);
    }
  };
  const imageChange6 = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage6(e.target.files[0]);
    }
  };

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = () => {
    setSelectedImage();
  };

  
  const up_avatar = async () => {
    const uploadImage = async (image, imageName) => {
        if (image != null) {
            const form = new FormData();
            form.append(imageName, image);
            form.append('user_id', usertoken);
            form.append('ad_id', ad_id);
            form.append(`images_name${imageName.slice(-1)}`, images_name);

            try {
                const data = await axios({
                    method: 'post',
                    data: form,
                    url: API_URL + '/update_ad_images',
                    headers: { "Content-Type": "multipart/form-data" }
                });

                if (data && data.status === 200) {
                    console.log(`${imageName} uploaded successfully`);
                    window.location.href = '/upload_image/' + ad_id;
                    spin_success();
                }
            } catch (error) {
                console.error(`Error uploading ${imageName}:`, error);
            }
        }
    };

    // Проверяем и загружаем все изображения
    await uploadImage(selectedImage, 'avatar');
    await uploadImage(selectedImage1, 'image1');
    await uploadImage(selectedImage2, 'image2');
    await uploadImage(selectedImage3, 'image3');
    await uploadImage(selectedImage4, 'image4');
    await uploadImage(selectedImage5, 'image5');
    await uploadImage(selectedImage6, 'image6');
};
  const spin_success = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
    setTimeout(() => {
      success();
      // window.location.href='/put_user2'
    }, 500);
  };
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Фото профиля изменено!',
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Выберите фото!',
    });
  };
  


  useEffect(() => {
    useridtoken();
    fetchProducts2();
  }, []);
  return (
    <>
    {contextHolder}
     {product != null || product != undefined || product?.length > 0 ?
                    <>
      <Nav />
      <div  className="container ">
        <div class="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 card">
            <div class="row">
              <div className='col-md-12'>
                <h5>Редактировать</h5>
              </div>
              <div className='row'>
          
                  <label style={{ fontSize: '18px', fontFamily: 'unset' }}>ID: {product[0].id}</label>
                   {selectedImage && (
          <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-12">
             <label class="label2">
             <img
              style={{width:'150px',height:'150px'}}
              src={URL.createObjectURL(selectedImage)}
              // style={styles.image}
              alt="Thumb"
            />
            <button onClick={up_avatar} style={{width:'100%'}} className="btn btn-primary">
              Заменить
            </button>
             </label>
           
          </div>
        )}
              {selectedImage ? <></>:<> 
              <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                              <label class="label2">
                              <img  style={{width:'100%',height:'90px'}} src={product[0].images != null ? (uploads + product[0].images) : (uploads + 'no_image.jpg')} />
                                <input  accept="image/*"
                                onChange={imageChange} type="file"/>
                                <button onClick={up_avatar}> Заменить главное фото</button>
                              </label>
                          </div>
                </>
              }
                  {selectedImage1 && (
                    
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img
                       style={{width:'150px',height:'150px'}}
                        src={URL.createObjectURL(selectedImage1)}
                        // style={styles.image}
                        alt="Thumb"
                      />
                      <button onClick={up_avatar} style={{width:'100%'}} className="btn btn-primary">
              Заменить
            </button>
                      </label>
                    </div>
                  )}
                  {selectedImage1 ? <>
                  </>
                  :<> 
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img  style={{width:'100%',height:'90px'}} src={product[0].image1 != null ? (uploads + product[0].image1) : (uploads + 'no_image.jpg')} />
                        <input  accept="image/*"
                        onChange={imageChange1} type="file"/>
                        <button onClick={up_avatar}> Заменить фото 1</button>
                      </label>
                  </div>
                  </>}
                  {selectedImage2 && (
                    
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                       <label class="label2">
                      <img
                        style={{width:'150px',height:'150px'}}
                        src={URL.createObjectURL(selectedImage2)}
                        // style={styles.image}
                        alt="Thumb"
                      />
                       <button onClick={up_avatar} style={{width:'100%'}} className="btn btn-primary">
              Заменить
            </button>
                      </label>
                    </div>
                  )}
                  {selectedImage2 ? <>
                  </>
                  :<>
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img  style={{width:'100%',height:'90px'}} src={product[0].image2 != null ? (uploads + product[0].image2) : (uploads + 'no_image.jpg')} />
                        <input  accept="image/*"
                        onChange={imageChange2} type="file"/>
                        <button onClick={up_avatar}> Заменить фото 2</button>
                      </label>
                  </div>
                </>}
                  {selectedImage3 && (
                    
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img
                        style={{width:'150px',height:'150px'}}
                        src={URL.createObjectURL(selectedImage3)}
                        // style={styles.image}
                        alt="Thumb"
                      />
                       <button onClick={up_avatar} style={{width:'100%'}} className="btn btn-primary">
              Заменить
            </button>
                      </label>
                    </div>
                  )}
                  {selectedImage3 ? <>
                  </>
                  :<> 
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img  style={{width:'100%',height:'90px'}} src={product[0].image3 != null ? (uploads + product[0].image3) : (uploads + 'no_image.jpg')} />
                        <input  accept="image/*"
                        onChange={imageChange3} type="file"/>
                        <button onClick={up_avatar}> Заменить фото 3</button>
                      </label>
                  </div>
                 </>}
                  {selectedImage4 && (
                    
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img
                        style={{width:'150px',height:'150px'}}
                        src={URL.createObjectURL(selectedImage4)}
                        // style={styles.image}
                        alt="Thumb"
                      />
                      <button onClick={up_avatar} style={{width:'100%'}} className="btn btn-primary">
              Заменить
            </button>
                      </label>
                    </div>
                  )}
                  {selectedImage4 ? <>
                  </>
                  :<> 
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img  style={{width:'100%',height:'90px'}} src={product[0].image4 != null ? (uploads + product[0].image4) : (uploads + 'no_image.jpg')} />
                        <input  accept="image/*"
                        onChange={imageChange4} type="file"/>
                        <button onClick={up_avatar}> Заменить фото 4</button>
                      </label>
                  </div>
              </>}
                  {selectedImage5 && (
                    
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img
                        style={{width:'150px',height:'150px'}}
                        src={URL.createObjectURL(selectedImage5)}
                        // style={styles.image}
                        alt="Thumb"
                      />
                      <button onClick={up_avatar} style={{width:'100%'}} className="btn btn-primary">
              Заменить
            </button>
                      </label>
                    </div>
                  )}
                  {selectedImage5 ? <>
                  </>
                  :<> 
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img  style={{width:'100%',height:'90px'}} src={product[0].image5 != null ? (uploads + product[0].image5) : (uploads + 'no_image.jpg')} />
                        <input  accept="image/*"
                        onChange={imageChange5} type="file"/>
                        <button onClick={up_avatar}> Заменить фото 5</button>
                      </label>
                  </div>
              </>}
                  {selectedImage6 && (
                    
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img
                        style={{width:'150px',height:'150px'}}
                        src={URL.createObjectURL(selectedImage6)}
                        // style={styles.image}
                        alt="Thumb"
                      />
                       <button onClick={up_avatar} style={{width:'100%'}} className="btn btn-primary">
              Заменить
            </button>
                      </label>
                    </div>
                  )}
                  {selectedImage6 ? <>
                  </>
                  :<> 
                    <div style={{padding:'1.5px'}} class="example-1 col-md-auto col-auto">
                      <label class="label2">
                      <img  style={{width:'100%',height:'90px'}} src={product[0].image6 != null ? (uploads + product[0].image6) : (uploads + 'no_image.jpg')} />
                        <input  accept="image/*"
                        onChange={imageChange6} type="file"/>
                        <button onClick={up_avatar}> Заменить фото 6</button>
                      </label>
                  </div>
                  </>}
                  <div> 
                  </div> 
              </div>
              <div className='col-md-12 mt-2'>
                <Button type="primary" onClick={update}>
                  Сохранить
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
     <Footer />
            </>
                    : <>Loading</>
                  }
    </>
  )
}
export default Upload_image;