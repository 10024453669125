import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  IconButton, 
  Badge, 
  InputBase, 
  Avatar, 
  Tooltip,
  Menu,
  MenuItem
} from '@mui/material';
import { 
  Mail as MailIcon, 
  Favorite as FavoriteIcon, 
  AccountCircle, 
  ExitToApp, 
  Add, 
  Search as SearchIcon,
  Home as HomeIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../config/config';

const Nav = () => {
  const [logged, setLogged] = useState(false);
  const [status, setStatus] = useState(false);
  const [usertoken, setUsertoken] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      const g = localStorage.getItem('g');
      if (token) {
        try {
          const response = await axios.get(`${API_URL}/users5`, { params: { id: token } });
          if (response.data.status === 200) {
            setLogged(true);
            setUsertoken(response.data.users5[0].id);
          }
        } catch (error) {
          console.error('Ошибка проверки токена:', error);
          localStorage.clear();
          setLogged(false);
        }
      }
      if (g) {
        try {
          const response = await axios.get(`${API_URL}/users5`, { params: { id: g } });
          if (response.data.status === 200) {
            setLogged(true);
            setUsertoken(response.data.users5[0].id);
          }
        } catch (error) {
          console.error('Ошибка проверки токена:', error);
          localStorage.clear();
          setLogged(false);
        }
      }
    };
    checkAuth();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setLogged(false);
    navigate('/');
    handleCloseMenu();
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar 
     
      position="sticky" 
      color="default" 
      elevation={1}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        backdropFilter: 'blur(10px)',
        borderBottom: '1px solid rgba(0,0,0,0.1)'
      }}
    >
      <Toolbar sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        maxWidth: 'lg',
        margin: '0 auto',
        width: '100%',
        padding: theme => theme.spacing(0, 2)
      }}>
        {/* Логотип */}
        <Typography 
          
          sx={{ 
            fontWeight: 'bold',
            fontSize:'36px',
            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'Cinzel, serif '}}>
            Birimdik
          </Link>
        </Typography>

        {/* Поиск */}
        {/* <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          backgroundColor: '#f0f0f0', 
          borderRadius: '24px', 
          padding: '4px 12px',
          marginLeft: '16px',
          marginRight: '16px'
        }}>
          <SearchIcon sx={{ color: 'action.active', mr: 1 }} />
          <InputBase 
            placeholder="Я ищу..." 
            sx={{ 
              flexGrow: 1,
              '& .MuiInputBase-input': {
                padding: '8px 0'
              }
            }} 
          />
        </div> */}

        {/* Навигационные кнопки */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {logged ? (
            <>
              <Tooltip title="Главная">
                <IconButton color="primary" component={Link} to="/">
                  <HomeIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Добавить">
                <IconButton color="primary" component={Link} to="/upload1/0">
                  <Add />
                </IconButton>
              </Tooltip>

              <Tooltip title="Сообщения">
                <IconButton color="primary" component={Link} to={`/mainsms/${usertoken}`}>
                  <Badge color="error" variant={status ? 'dot' : ''}>
                    <MailIcon />
                  </Badge>
                </IconButton>
              </Tooltip>

              <Tooltip title="Избранное">
                <IconButton color="primary" component={Link} to="/korzina">
                  <FavoriteIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Профиль">
                <IconButton 
                  color="primary" 
                  onClick={handleOpenMenu}
                >
                  <Avatar 
                    sx={{ 
                      width: 32, 
                      height: 32, 
                      bgcolor: 'primary.main' 
                    }}
                  >
                    <PersonIcon />
                  </Avatar>
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={() => navigate(`/userinfo/${usertoken}`)}>
                  <PersonIcon sx={{ mr: 1 }} /> Профиль
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ExitToApp sx={{ mr: 1 }} /> Выход
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <Button 
                variant="outlined" 
                color="primary" 
                component={Link} 
                to="/vhod"
                sx={{ 
                  mr: 1,
                  textTransform: 'none',
                  borderRadius: '24px'
                }}
              >
                Вход
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => navigate('/registr')}
                sx={{ 
                  borderRadius: '24px',
                  textTransform: 'none'
                }}
              >
                Регистрация
              </Button>
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;