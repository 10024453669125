// import React, { useState, useEffect } from 'react';
// import { Image } from 'antd';
// import moment from 'moment-timezone';
// import { uploads, API_URL } from '../config/config';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';


// import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
// import { Button, Modal, Radio, Spin, Tabs , Space } from 'antd';
// import { message, Upload } from 'antd';
// import { AudioOutlined, MediumCircleFilled } from '@ant-design/icons';
// import { useDispatch, useSelector } from 'react-redux';
// import { setLikes } from '../redux/actions/like_actions';
// import { setProducts } from '../redux/actions/products_actions ';
// import { Cloud } from '@mui/icons-material';
// import { Box, Chip } from '@mui/joy';


// const Post2_like = ({ product }) => {
//   const [product3, setProduct3] = useState(null);
//   const [products_category, setProducts_category] = useState(null);
//   const [products_metro, setProducts_metro] = useState(null);
//   const [favorited, setFavorited] = useState(null);

//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchProducts_category();
//     fetchProducts_metro();
//     fetchProduct3();
//   }, []);

//   const fetchProducts_category = async () => {
//     try {
//       const data = await axios.get(API_URL + '/cat_get');
//       if (data.data.status === 200) {
//         setProducts_category(data.data.cat_get);
//       }
//     } catch (error) {
//       console.error('Ошибка при получении категорий:', error);
//     }
//   };

//   const fetchProducts_metro = async () => {
//     try {
//       const data = await axios.get(API_URL + '/getmetro');
//       if (data.data.status === 200) {
//         setProducts_metro(data.data.getmetro);
//       }
//     } catch (error) {
//       console.error('Ошибка при получении метро:', error);
//     }
//   };

//   const fetchProduct3 = async () => {
//     try {
//       const data = await axios.get(API_URL + '/users_get');
//       if (data.data.status === 200) {
//         setProduct3(data.data.users_get);
//       }
//     } catch (error) {
//       console.error('Ошибка при получении пользователей:', error);
//     }
//   };

      
//     const [messageApi, contextHolder] = message.useMessage();
//     const dispatch = useDispatch();
//     const { likes } = useSelector((state) => state.likes);
//     const { products } = useSelector((state) => state.products);
//     const [product4, setProduct4] = useState(null);
//     const [message1, setMessage1] = useState(null);
//     const limit2 = localStorage.getItem('limit');
//     const [limit, setLimit] = useState(localStorage.getItem('limit'));
//     const [logged, setLogged] = useState(false);
//     const [ad_id, setAd_id] = useState(null);
//     const [user2_id, setUser2_id] = useState(null);
//     const [user_id, setUser_id] = useState(null);
//     const [usertoken, setUsertoken] = useState(null);
//     const useridtoken = async () => {
//         if(localStorage.getItem('token') != null){
//             const post = {
//                 id: localStorage.getItem('token'),
//             };
//             const data = await axios({
//                 method: 'get',
//                 params: post,
//                 url: API_URL + '/users5'
//             });

//             if (data.data.status == 200) {
                
//                 setUsertoken(data.data.users5[0].id);
//                 Logged(data.data.users5[0].id);
//             }if(localStorage.getItem('g') != null){
//                 const post = {
//                     id: localStorage.getItem('g'),
//                 };
//                 const data = await axios({
//                     method: 'get',
//                     params: post,
//                     url: API_URL + '/users5'
//                 });

//                 if (data.data.status == 200) {

//                     setUsertoken(data.data.users5[0].id);
//                     Logged(data.data.users5[0].id);
//                 }
//         }
//             } 
//       }
    
//     const Logged = (usertoken) => {
//         if (usertoken != null) {
//             // 
//             setUsertoken(usertoken);
//             setLogged(true);
//         }
//     }

// const fetchProducts_metro2 = async () => {
// const data = await axios({
//   method: 'get',
//   url: API_URL + '/getmetro'
// });
// if (data.data.status == 200){
//   setProducts_metro(data.data.getmetro);
// //   
// } else {
  
// }
// }
// const [products_click, setProducts_click] = useState(null);
// const fetchProducts_click = async () => {
// const data = await axios({
//   method: 'get',
//   url: API_URL + '/getclick'
// });
// // 
// if (data.data.status == 200){
//   setProducts_click(data.data.getview);
// //   
// } else {
  
// }
// }
  
    


// const chat_if = async () => {
//     if (logged == true) {
//         const params = {
//             ad_id: ad_id,
//             user1_id: usertoken,
//             user2_id: user2_id,
//         };
//         const data = await axios({
//             method: 'get',
//             params: params,
//             url: API_URL + '/chat_if'
//         });
        
//         if (data.data.status == 200){
//             chat(data.data.chat_if[0].ad_id,data.data.chat_if[0].id);
//         } else {
//            error();
            
//             chats();
//             // window.location.href="/mainsms/";
            
//         }
//     } else {
//        warning();
//     }

// }
    
//     const chats = async ()=>{
//         const params = {
//             ad_id:ad_id,
//             user1_id:usertoken,
//             user2_id:user2_id,
//         };
//         const data = await axios({
//             method:'post',
//             params:params,
//             url:API_URL+'/chats'
//         });
        
//         if(data != null){
//             if(data.status  == 200){
            
//             // fetchProducts();
//             localStorage.setItem('chat',message1);
//             setTimeout(chat_if,2000);
//             // window.location.href="/mainsms/"+product[0].id;
//             }else{
//                 alert('Error')
//             }
//         }
//     }
    
//     const chat = async (ad_id,room_id) => {
//         if(usertoken != null){
//             const params = {
//                 ad_id: ad_id,
//                 user_id: usertoken,
//                 message: message1,
//                 room_id: room_id,
//                 user_id2: user2_id
//             };
//             const data = await axios({
//                 method: 'post',
//                 params: params,
//                 url: API_URL + '/chat'
//             });
            
//             if (data != null){
//                 if (data.status == 200){
//                     chats_date(room_id);
//                     success();
//                 } else {
//                     alert('Error')
//                 }
//             }
//         }
//     }
//     const chats_date = async (room_id)=>{
//         const params = {
//             room_id:room_id
//         };
//         const data = await axios({
//             method:'put',
//             params:params,
//             url:API_URL+'/chats'
//         });
        
//         if(data != null){
//             if(data.data.status  == 200){
            
//             }else{
//                 alert('Error')
//             }
//         }
//     }
//     const fetchProducts2 = async (usertoken) => {
//         if(usertoken != null){
//             const post = {
//                 user_id: usertoken,
//             };
//             const data = await axios({
//                 method: 'get',
//                 params: post,
//                 url: API_URL+'/get_favorites'
//             });
            
//             if (data.data.status == 200) {
//                 dispatch(setLikes(data.data.favorites));
                
//             } else {
                
                
//             }
//         }
       
//       }


//     const addfavorites = async (ad_id) => {
//         if(usertoken != null){
//             const params = {
//                 ad_id: ad_id,
//                 user_id: usertoken,
//             };
//             const data = await axios({
//                 method: 'post',
//                 params: params,
//                 url: API_URL+'/favorites'
//             });
//             console.log(data);
//             if (data != null) {
//                 if (data.data.status == 200) {
                   
                    
//                     setFavorited(true);
//                     success3();
//                     fetchProducts2(usertoken); 
//                 } else {
  
//                 }
//             }
//         }else{
//             warning();
//         }
        
//     }
                
//     const delfavorites = async (ad_id) => {
//         if(usertoken != null){
//             const params = {
//                 ad_id: ad_id,
//                 user_id: usertoken,
//             };
            
//             const data = await axios({
//                 method: 'delete',
//                 params: params,
//                 url: API_URL+'/delete_favorites'
//             });
            
//             if (data != null) {
//                 if (data.data.status == 200) {
                    
//                     setFavorited(false);
//                     success4();
//                     fetchProducts2(usertoken);
                    
//                 } else {
//                 }
//             }
//         }
        
//     }

    

//     const fetchProduct4 = async () => {
//         const post = {
//             id: product.userid,
//         };
//         const data = await axios({
//             method: 'get',
//             params: post,
//             url: API_URL +'/users1'
//         });
//         // 
//         if (data.data.status == 200) {
//             setProduct4(data.data.users1);
//             // 
//         } else {
            
            
//         }
//     }
    


//     const checkLikes = (id) => {
//         // 
//         const check = likes.filter(i => i.ad_id == id);
//         if (check.length > 0) {
//             setFavorited(true);
//             return true;
//         } else {
//             setFavorited(false);
//             return false;
//         }
//     }
//     if (favorited == null) { 
//             checkLikes(product.id);
//     }
    
// const success = () => {
//     messageApi.open({
//       type: 'success',
//       content: 'Сообщение отправлено!',
//     });
//   };
//   const success3 = () => {
//     messageApi.open({
//       type: 'success',
//       content: 'Добавлено в избранное!',
//     });
//   };
//   const success4 = () => {
//     messageApi.open({
//       type: 'success',
//       content: 'Удалено из избранное!',
//     });
//   };
 
//   const error = () => {
//     messageApi.open({
//       content:  <Spin /> ,
//     });
//   };

//   const warning = () => {
//     messageApi.open({
//       type: 'warning',
//       content: 'Авторизуйтесь!',
//     });
// }




//     useEffect(() => {
//         useridtoken(); 
//         fetchProducts_category(); 
//             fetchProducts_click();
//             fetchProduct3();
//             Logged();
//             fetchProduct4();
//             fetchProducts_metro2();  
//     }, []);

    
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [clicks, setClicks] = useState(false);
//     const showModal = (id,user2_id) => {
//         setAd_id(id);
//         setUser2_id(user2_id);
//       setIsModalOpen(true);
      
//     };
//     // 
//     const handleOk = () => {
//         chat_if();
//       setIsModalOpen(false);
//     };
//     const handleCancel = () => {
//       setIsModalOpen(false);
//     };

//     const suffix = (
//         <AudioOutlined
//             style={{
//                 fontSize: 16,
//                 color: '#1890ff',
//             }}
//         />
//     );
   
   
//       const [searchItem, setSearchItem] = useState('')
     

//       const handleInputChange = (e) => { 
//         const searchTerm = e.target.value;
//         setSearchItem(searchTerm)
//       }
      

     
//       const click = async (ad_id) => {
//         if (ad_id != null) {
//             const params = {
//                 ad_id: ad_id,
//             };
//             const data = await axios({
//                 method: 'get',
//                 params: params,
//                 url: API_URL + '/click_post'
//             });
            
//             if (data.data.status == 200){
//                 add_click(ad_id);
//                 // setClicks(data.data.click_post);
                
//             } else {
//                new_click(ad_id);
//             }
//         } else {
//         }
//     }
//     const add_click = async (ad_id) => {
//         const params = {
//             ad_id: ad_id
//         };
//         const data = await axios({
//             method: 'put',
//             params: params,
//             url: API_URL + '/click_post'
//         });
        
//         if (data != null){
//             if (data.status == 200){
//                 navigate('/post/'+ad_id);
//                 // window.location.href='/post/'+ad_id
//             } else {
//             }
//         }
//     }
//     const new_click = async (ad_id) => {
//         const params = {
//             ad_id: ad_id,
//             click: 1
//         };
//         const data = await axios({
//             method: 'post',
//             params: params,
//             url: API_URL + '/click_post'
//         });
        
//         if (data != null){
//             if (data.status == 200){
//                 navigate('/post/'+ad_id);
//                 // window.location.href='/post/'+ad_id
//             } else {
//             }
//         }
//     }
//     const fetchProductClicks = async () => {
//       try {
//           const response = await axios.get(API_URL + '/click_post', {
//               params: { ad_id: product.id },
//           });
//           if (response.data.status === 200) {
//               setClicks(response.data.clicks); // Сохраняем количество просмотров в состоянии
//           } else {
//               console.error('Не удалось получить количество просмотров');
//           }
//       } catch (error) {
//           console.error('Ошибка при получении количества просмотров:', error);
//       }
//   };
  
   

//   const renderMetroInfo = (filteredMetro) => {
//     const metroColor = filteredMetro.color !== 1 ? `#${filteredMetro.color}` : 'gray'; // Используем цвет из filteredMetro.color
  
//     return (
//       <div className="flex items-center text-gray-600 text-sm">
//         {filteredMetro.color !== 1 ? (
//           <>
//             <span className="mr-2 truncate text-xs">{filteredMetro.Station}</span>

//             <div 
//               className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full flex items-center justify-center text-xs font-bold"
//               style={{ backgroundColor: metroColor, color: 'white' }} // Применяем цвет фона и белый цвет для буквы
//             >
//               M
//             </div>
//           </>
//         ) : (
//           <>
//             <span className="mr-2 truncate">{filteredMetro.Station}</span>
//             <img 
//               src={filteredMetro.image} 
//               alt="Metro" 
//               className="w-5 h-5 rounded-full flex-shrink-0" 
//             />
//           </>
//         )}
//       </div>
//     );
//   };
  
  

//   const renderUserAvatar = (filteredAvatar) => (
//     <a href={'/user/' + filteredAvatar.id}>
//       <img
//       src={filteredAvatar.avatar ? uploads + filteredAvatar.avatar : uploads + 'user.jpg'}
//       alt="User Avatar"
//       className="w-8 h-8 rounded-full object-cover flex-shrink-0"
//       />
//     </a>
//   );

//   const formatPrice = (price) => {
//     // Проверка на число и форматирование с разделителем тысяч
//     const priceNumber = parseFloat(price); // Преобразуем строку в число, если это необходимо
//     if (isNaN(priceNumber)) {
//       return '—'; // Если цена не число, возвращаем дефолтное значение
//     }
//     return priceNumber.toLocaleString('ru-RU'); // Используем 'ru-RU' для форматирования с точкой как разделителем
//   };
  
//   if (!product ) {
//     return <div>Загрузка...</div>; // Покажите индикатор загрузки или текст
//   }
  
//   return (
    
//     <div className="flex flex-col h-full bg-white border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
//       <Modal  title="Отправить сообщение" open={isModalOpen} onOk={handleOk} okText='Отправить' cancelText="Отмена" onCancel={handleCancel}>
//     <textarea onChange={(e) => { setMessage1(e.target.value) }} style={{height:'100px'}} className="form-control"></textarea>
//      </Modal>
//        {contextHolder}
//       {/* Контейнер изображения */}
      
//       <div 
//         className="relative h-56 w-full overflow-hidden cursor-pointer"
        
//       >
//         {/* Знак категории */}
//         {products_category && (
//           <div className="absolute top-2 left-2 z-1">
//             {products_category
//               .filter((category) => category.id === product.categories)
//               .map((filteredCategory) => (
//                 filteredCategory.name && (
//                   <span
//                     key={filteredCategory.id}
//                     className="bg-green-600 text-white text-xs px-2 py-1 rounded"
//                   >
//                     {filteredCategory.name}
//                   </span>
//                 )
//               ))}
//           </div>
//         )}

//         {/* Кнопка "Избранное" */}
        
// {favorited ?
// <>
//   <button 
//     onClick={() => delfavorites(product.id)} className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
//   <i  className={`fa-heart  fa-solid text-red-400 fa-regular text-xl`} />
//   </button>
// </>
// : <>
//    <button 
//     onClick={() => addfavorites(product.id)} className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
//   <i  className={`fa-heart  fa-regular text-xl`} />
//   </button>
// </>
// }



//         {/* Изображение продукта */}
//         <img
//           onClick={() => click(product.id)}
//           src={product.images ? uploads + product.images : uploads + 'no_user.jpg'}
//           alt="Image"
//           className="w-full h-full object-cover"
//         />
//       </div>

//       {/* Детали продукта */}
//     <div className="flex flex-col flex-grow p-1 ">
//       {/* Цена */}
//       <div className="flex justify-between items-center mb-0 ">
//         <h2 className="text-lg  font-bold text-black roboto mb-0">
//           {product.price != 0 
//             ? `${formatPrice(product.price)} ${product.currency}` 
//             : 'Договорная'}
//         </h2>
//         {/* {products_click != null ? <>
//         {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
//       <a>
//           {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'3%',fontSize:'10px',zIndex:'1',padding:'1px'}} type="button" classname="btn btn-dark">Посмотрели: {filteredView.click}</button></> :
//             <>
//           </>}
//       </a>
//       ))}
//       </> : <>
//       </>} */}
//       </div>

//         {/* Описание */}
//         <p className="text-gray-700 text-sm mb-0 h-10 overflow-hidden line-clamp-2 font-roboto">
//             {product.descriptions}
//         </p>


//         {/* Информация о метро */}
//         <div className="flex justify-between items-center mb-3">
            

            
//           <div className="flex-grow overflow-hidden">
//             {products_metro &&
//               products_metro
//                 .filter((metro) => metro.id_sql === product.metro)
//                 .map((filteredMetro) => (
//                   <div key={filteredMetro.id_sql}>
//                     {renderMetroInfo(filteredMetro)}
//                   </div>
//                 ))}
//           </div>
          
//           <div className="flex flex-col sm:flex-row items-start sm:items-center">
//   <span className="text-xs sm:text-sm md:text-base lg:text-lg text-gray-500 ml-2 flex-shrink-0 block sm:inline">
//     {moment.tz(product.date1, 'Europe/Moscow').fromNow()}
//   </span>
//   {/* Добавьте другие элементы, которые нужно отобразить рядом */}
// </div>


//         </div>

//         {/* Информация о пользователе */}
//         <div className="mt-auto flex justify-between items-center">
//           {product3 &&
//             product3
//               .filter((p) => p.id === product.userid)
//               .map((filteredAvatar) => (
//                 <div 
//                   key={filteredAvatar.id} 
//                   className="flex items-center"
//                 >
//                   {renderUserAvatar(filteredAvatar)}
//                   <a href={'/user/' + filteredAvatar.id}>
//                   <span className="ml-2 text-xs text-gray-600 truncate">
//                     {filteredAvatar.name || 'Пользователь'}
//                   </span>
//                   </a>
                  
//                 </div>
//               ))}

//           {/* Кнопка сообщения */}
//           <button 
//   onClick={() => showModal(product.id,product.userid)}
//   className="flex items-center justify-center bg-green-600 text-white hover:bg-green-700 transition-colors duration-200 ease-in-out rounded-full py-2 px-6 text-xs font-semibold shadow-lg transform active:scale-95"
//   aria-label="Написать сообщение"
// >
//   Написать
// </button>



//         </div>
//       </div>
//     </div>
//   );
// };

// export default Post2_like;









import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { Button, Modal, Radio, Spin, Tabs , Space } from 'antd';
import { message, Upload } from 'antd';
import { AudioOutlined, MediumCircleFilled } from '@ant-design/icons';
import { Image } from 'antd';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLikes } from '../redux/actions/like_actions';
import moment from 'moment-timezone';
import { API_URL, uploads } from '../config/config';
import { setProducts } from '../redux/actions/products_actions ';
import { Cloud } from '@mui/icons-material';
import { Box, Chip } from '@mui/joy';
const Post2_like = ({ product }) => {

    
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const { likes } = useSelector((state) => state.likes);
    const { products } = useSelector((state) => state.products);
    const [favorited, setFavorited] = useState(true);
    const [product3, setProduct3] = useState(null);
    const [product4, setProduct4] = useState(null);
    const [message1, setMessage1] = useState(null);
    const limit2 = localStorage.getItem('limit');
    const [limit, setLimit] = useState(localStorage.getItem('limit'));
    const [logged, setLogged] = useState(false);
    const [ad_id, setAd_id] = useState(null);
    const [user2_id, setUser2_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [usertoken, setUsertoken] = useState(null);
    const [searchItem, setSearchItem] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [clicks, setClicks] = useState(false);
const [products_category, setProducts_category] = useState(null);
const [products_click, setProducts_click] = useState(null);
    const [products_metro, setProducts_metro] = useState();
    const useridtoken = async () => {
        if(localStorage.getItem('token') != null){
            const post = {
                id: localStorage.getItem('token'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
      
            if (data.data.status == 200) {
                
                setUsertoken(data.data.users5[0].id);
                Logged(data.data.users5[0].id);
            }if(localStorage.getItem('g') != null){
                const post = {
                    id: localStorage.getItem('g'),
                };
                const data = await axios({
                    method: 'get',
                    params: post,
                    url: API_URL + '/users5'
                });
              
                if (data.data.status == 200) {
               
                    setUsertoken(data.data.users5[0].id);
                    Logged(data.data.users5[0].id);
                }
        }
            } 
      }
    
    const Logged = (usertoken) => {
        if (usertoken != null) {
       
            setUsertoken(usertoken);
            setLogged(true);
        }
    }
    
const fetchProducts_metro2 = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getmetro'
});
if (data.data.status == 200){
  setProducts_metro(data.data.getmetro);

} else {
  
}
}

const fetchProducts_click = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/getclick'
});
// 
if (data.data.status == 200){
  setProducts_click(data.data.getview);
  
} else {
  
}
}

const fetchProducts_category = async () => {
const data = await axios({
  method: 'get',
  url: API_URL + '/cat_get'
});

if (data.data.status == 200){
  setProducts_category(data.data.cat_get);
 
} else {
  
}
}
    const fetchProduct3 = async () => {
        const data = await axios({
            method: 'get',
            url: API_URL +'/users_get'
        });
        if (data.data.status == 200) {
            setProduct3(data.data.users_get);
            // 
        } else {
            
        }
    }
    
   
const chat_if = async () => {
    if (logged == true) {
        const params = {
            ad_id: ad_id,
            user1_id: usertoken,
            user2_id: user2_id,
        };
        const data = await axios({
            method: 'get',
            params: params,
            url: API_URL + '/chat_if'
        });
        
        if (data.data.status == 200){
            chat(data.data.chat_if[0].ad_id,data.data.chat_if[0].id);
        } else {
           error();
            
            chats();
          
            
        }
    } else {
       warning();
    }

}
    
    const chats = async ()=>{
        const params = {
            ad_id:ad_id,
            user1_id:usertoken,
            user2_id:user2_id,
        };
        const data = await axios({
            method:'post',
            params:params,
            url:API_URL+'/chats'
        });
        
        if(data != null){
            if(data.status  == 200){
            
        
            localStorage.setItem('chat',message1);
            setTimeout(chat_if,2000);
          
            }else{
                alert('Error')
            }
        }
    }
    
    const chat = async (ad_id,room_id) => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
                message: message1,
                room_id: room_id,
                user_id2: user2_id
            };
            const data = await axios({
                method: 'post',
                params: params,
                url: API_URL + '/chat'
            });
            
            if (data != null){
                if (data.status == 200){
                    chats_date(room_id);
                    success();
                } else {
                    alert('Error')
                }
            }
        }
    }
    const chats_date = async (room_id)=>{
        const params = {
            room_id:room_id
        };
        const data = await axios({
            method:'put',
            params:params,
            url:API_URL+'/chats'
        });
        
        if(data != null){
            if(data.data.status  == 200){
            
            }else{
                alert('Error')
            }
        }
    }
    const fetchProducts2 = async (usertoken) => {
        if(usertoken != null){
            const post = {
                user_id: usertoken,
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL+'/get_favorites'
            });
            
            if (data.data.status == 200) {
                dispatch(setLikes(data.data.favorites));
                
            } else {
                
                
            }
        }
       
      }
     

     
                
    const delfavorites = async (ad_id) => {
        if(usertoken != null){
            const params = {
                ad_id: ad_id,
                user_id: usertoken,
            };
            
            const data = await axios({
                method: 'delete',
                params: params,
                url: API_URL+'/delete_favorites'
            });
            
            if (data != null) {
                if (data.data.status == 200) {
                    
                    setFavorited(false);
                    success4();
                    fetchProducts2(usertoken);
                    
                } else {
                }
            }
        }
        
    }

    

    const fetchProduct4 = async () => {
        const post = {
            id: product.userid,
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL +'/users1'
        });
    
        if (data.data.status == 200) {
            setProduct4(data.data.users1);
         
        } else {
            
            
        }
    }
    


    const checkLikes = (id) => {
       
        const check = likes.filter(i => i.ad_id == id);
        if (check.length > 0) {
            setFavorited(true);
            return true;
        } else {
            setFavorited(false);
            return false;
        }
    }
    if (favorited == null) { 
            checkLikes(product.ad_id);
    }
    
const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Сообщение отправлено!',
    });
  };
  const success2 = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const success3 = () => {
    messageApi.open({
      type: 'success',
      content: 'Добавлено в избранное!',
    });
  };
  const success4 = () => {
    messageApi.open({
      type: 'success',
      content: 'Удалено из избранное!',
    });
  };
 
  const error = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };

  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Авторизуйтесь!',
    });
}




    useEffect(() => {
        useridtoken(); 
        fetchProducts_category(); 
            fetchProducts_click();
            fetchProduct3();
            Logged();
            fetchProduct4();
            fetchProducts_metro2();  
    }, []);

    
    const showModal = (id,user2_id) => {
        setAd_id(id);
        setUser2_id(user2_id);
      setIsModalOpen(true);
      
    };
    // 
    const handleOk = () => {
        chat_if();
      setIsModalOpen(false);
    };
    const handleCancel = () => {
      setIsModalOpen(false);
    };


   
      
     

      const handleInputChange = (e) => { 
        const searchTerm = e.target.value;
        setSearchItem(searchTerm)
      }
      

     
      const click = async (ad_id) => {
        if (ad_id != null) {
            const params = {
                ad_id: ad_id,
            };
            const data = await axios({
                method: 'get',
                params: params,
                url: API_URL + '/click_post'
            });
            
            if (data.data.status == 200){
                add_click(ad_id);
                // setClicks(data.data.click_post);
                
            } else {
               new_click(ad_id);
            }
        } else {
        }
    }
    const add_click = async (ad_id) => {
        const params = {
            ad_id: ad_id
        };
        const data = await axios({
            method: 'put',
            params: params,
            url: API_URL + '/click_post'
        });
        
        if (data != null){
            if (data.status == 200){
                window.location.href='/post/'+ad_id
            } else {
            }
        }
    }
    const new_click = async (ad_id) => {
        const params = {
            ad_id: ad_id,
            click: 1
        };
        const data = await axios({
            method: 'post',
            params: params,
            url: API_URL + '/click_post'
        });
        
        if (data != null){
            if (data.status == 200){
                window.location.href='/post/'+ad_id
            } else {
            }
        }
    }
   
        const fetchProductClicks = async () => {
      try {
          const response = await axios.get(API_URL + '/click_post', {
              params: { ad_id: product.id },
          });
          if (response.data.status === 200) {
              setClicks(response.data.clicks); // Сохраняем количество просмотров в состоянии
          } else {
              console.error('Не удалось получить количество просмотров');
          }
      } catch (error) {
          console.error('Ошибка при получении количества просмотров:', error);
      }
  };
  
   

  const renderMetroInfo = (filteredMetro) => {
    const metroColor = filteredMetro.color !== 1 ? `#${filteredMetro.color}` : 'gray'; // Используем цвет из filteredMetro.color
  
    return (
      <div className="flex items-center text-gray-600 text-sm">
        {filteredMetro.color !== 1 ? (
          <>
            <span className="mr-2 truncate text-xs">{filteredMetro.Station}</span>

            <div 
              className="w-6 h-6 md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full flex items-center justify-center text-xs font-bold"
              style={{ backgroundColor: metroColor, color: 'white' }} // Применяем цвет фона и белый цвет для буквы
            >
              M
            </div>
          </>
        ) : (
          <>
            <span className="mr-2 truncate">{filteredMetro.Station}</span>
            <img 
              src={filteredMetro.image} 
              alt="Metro" 
              className="w-5 h-5 rounded-full flex-shrink-0" 
            />
          </>
        )}
      </div>
    );
  };
  
  

  const renderUserAvatar = (filteredAvatar) => (
    <a href={'/user/' + filteredAvatar.id}>
      <img
      src={filteredAvatar.avatar ? uploads + filteredAvatar.avatar : uploads + 'no_user.jpg'}
      alt="User Avatar"
      className="w-8 h-8 rounded-full object-cover flex-shrink-0"
      />
    </a>
  );

  const formatPrice = (price) => {
    // Проверка на число и форматирование с разделителем тысяч
    const priceNumber = parseFloat(price); // Преобразуем строку в число, если это необходимо
    if (isNaN(priceNumber)) {
      return '—'; // Если цена не число, возвращаем дефолтное значение
    }
    return priceNumber.toLocaleString('ru-RU'); // Используем 'ru-RU' для форматирования с точкой как разделителем
  };
  
  if (!product ) {
    return <div>Загрузка...</div>; // Покажите индикатор загрузки или текст
  }
     
    return (
    
        <div className="flex flex-col h-full bg-white border rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300">
          <Modal  title="Отправить сообщение" open={isModalOpen} onOk={handleOk} okText='Отправить' cancelText="Отмена" onCancel={handleCancel}>
        <textarea onChange={(e) => { setMessage1(e.target.value) }} style={{height:'100px'}} className="form-control"></textarea>
         </Modal>
          {<div style={{zIndex:'5'}}>{contextHolder}</div>}
          {/* Контейнер изображения */}
          
          <div 
            className="relative h-56 w-full overflow-hidden cursor-pointer"
            
          >
            {/* Знак категории */}
            {products_category && (
              <div className="absolute top-2 left-2 z-1">
                {products_category
                  .filter((category) => category.id === product.categories)
                  .map((filteredCategory) => (
                    filteredCategory.name && (
                      <span
                        key={filteredCategory.id}
                        className="bg-green-600 text-white text-xs px-2 py-1 rounded"
                      >
                        {filteredCategory.name}
                      </span>
                    )
                  ))}
              </div>
            )}
    
            {/* Кнопка "Избранное" */}
            
    {favorited ?
    <>
      <button 
        onClick={() => delfavorites(product.ad_id)} className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
      <i  className={`fa-heart  fa-solid text-red-400 fa-regular text-xl`} />
      </button>
    </>
    : <>
       <button 
        onClick={() => delfavorites(product.ad_id)} className="absolute top-2 right-2 z-1 text-white hover:text-red-300 bg-gray-700 hover:bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center p-0">
      <i  className={`fa-heart  fa-regular text-xl`} />
      </button>
    </>
    }
    
    
    
            {/* Изображение продукта */}
            <img
              onClick={() => click(product.id)}
              src={product.images ? uploads + product.images : uploads + 'no_image.jpg'}
              alt="Image"
              className="w-full h-full object-cover"
            />
          </div>
    
          {/* Детали продукта */}
        <div className="flex flex-col flex-grow p-1 ">
          {/* Цена */}
          <div className="flex justify-between items-center mb-0 ">
            <h2 className="text-lg  font-bold text-black roboto mb-0">
              {product.price != 0 
                ? `${formatPrice(product.price)} ${product.currency}` 
                : 'Договорная'}
            </h2>
            {/* {products_click != null ? <>
            {products_click.filter(view => view.ad_id == product.id).map(filteredView => (
          <a>
              {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'3%',fontSize:'10px',zIndex:'1',padding:'1px'}} type="button" classname="btn btn-dark">Посмотрели: {filteredView.click}</button></> :
                <>
              </>}
          </a>
          ))}
          </> : <>
          </>} */}
          </div>
    
            {/* Описание */}
            <p className="text-gray-700 text-sm mb-0 h-10 overflow-hidden line-clamp-2 font-roboto">
                {product.descriptions}
            </p>
    
    
            {/* Информация о метро */}
            <div className="flex justify-between items-center mb-3">
                
    
                
              <div className="flex-grow overflow-hidden">
                {products_metro &&
                  products_metro
                    .filter((metro) => metro.id_sql === product.metro)
                    .map((filteredMetro) => (
                      <div key={filteredMetro.id_sql}>
                        {renderMetroInfo(filteredMetro)}
                      </div>
                    ))}
              </div>
              
              <div className="flex flex-col sm:flex-row items-start sm:items-center">
      <span className="text-xs sm:text-sm md:text-base lg:text-lg text-gray-500 ml-2 flex-shrink-0 block sm:inline">
        {moment.tz(product.date1, 'Europe/Moscow').fromNow()}
      </span>
      {/* Добавьте другие элементы, которые нужно отобразить рядом */}
    </div>
    

            </div>
    
            {/* Информация о пользователе */}
            <div className="mt-auto flex justify-between items-center">
              {product3 &&
                product3
                  .filter((p) => p.id === product.userid)
                  .map((filteredAvatar) => (
                    <div 
                      key={filteredAvatar.id} 
                      className="flex items-center"
                    >
                      {renderUserAvatar(filteredAvatar)}
                      <a href={'/user/' + filteredAvatar.id}>
                      <span className="ml-2 text-xs text-gray-600 truncate">
                        {filteredAvatar.name || 'Пользователь'}
                      </span>
                      </a>
                      
                    </div>
                  ))}
    
              {/* Кнопка сообщения */}
              <button 
      onClick={() => showModal(product.id,product.userid)}
      className="flex items-center justify-center bg-green-600 text-white hover:bg-green-700 transition-colors duration-200 ease-in-out rounded-full py-2 px-6 text-xs font-semibold shadow-lg transform active:scale-95"
      aria-label="Написать сообщение"
    >
      Написать
    </button>
    
    
    
            </div>
          </div>
        </div>
      );
}
export default Post2_like;

// return (
//     <>
//    <div>

// </div>
// {contextHolder}
// <div>

// </div>
//   <Modal  title="Отправить сообщение" open={isModalOpen} onOk={handleOk} okText='Отправить' cancelText="Отмена" onCancel={handleCancel}>
//   <textarea onChange={(e) => { setMessage1(e.target.value) }} style={{height:'100px'}} className="form-control"></textarea>
//   </Modal>
     
//         {product != null || product != undefined || product ?.length > 0 ?
//             <>
//                             {/* {products_category != null ? <>
//                                 {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
//                             <a >
//                                 <label className=' medname'  style={{fontSize:'16px',fontFamily:'revert-layer',marginLeft:'10px'}}>{filteredCategory.name}</label>
//                             </a>
                            
//                             ))}
                            
//                             </> : <>
                            
//                             </>} */}
                            
//                 <div style={{width:'100%',marginLeft:'0px'}}  class="card obv" >
//                 {products_click != null ? <>
//                                 {products_click.filter(view => view.ad_id == product.ad_id).map(filteredView => (
//                             <a>
//                                 {filteredView.click != null ? <><button  style={{opacity:'0.7',borderRadius:'15%',fontSize:'10px',zIndex:'1',position:'absolute',padding:'1px'}} type="button" class="btn btn-dark"><i class="fa-solid fa-eye"></i> {filteredView.click}</button></> :
//                                  <>
//                                 </>}
//                             </a>
//                             ))}
//                             </> : <>
//                             </>}
//                             {products_category != null ? <>
//                                 {products_category.filter(category => category.id == product.categories).map(filteredCategory => (
//                             <a>
//                                 {filteredCategory.name != null ? <><button  style={{opacity:'0.7',borderRadius:'30%',fontSize:'10px',zIndex:'1',position:'absolute',padding:'1px',float:'right',right:'0'}} type="button" class="btn btn-dark">{filteredCategory.name}</button></> :
//                                  <>
//                                 </>}
//                             </a>
//                             ))}
//                             </> : <>
//                             </>}
//                     <a onClick={() => click(product.ad_id)} className='postimage2'>
//                         <div style={{pointerEvents:'none'}}>
//                             <Image  className='postimage2' style={{width:'100%',height:'180px',pointerEvents:'none'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} />
//                         </div>
//                          </a>
//                     <a onClick={() => click(product.ad_id)} className='postimage' >
//                     <div style={{pointerEvents:'none'}}>
//                         <Image className='postimage' style={{width: '100%',height:'140px',borderTopLeftRadius:'5px',borderTopRightRadius:'5px',pointerEvents:'none'}}  src={product.images != null ? (uploads + product.images) : (uploads + 'no_image.jpg')} /> 
//                     </div>
//                     </a>
//                     <div  style={{marginTop:'-17px'}} class="card-body medbody">

//                         <div class="row d-none d-md-flex">

//                             <div className='col-md-12'><span title='price' class="card-title txt7">{product.price != 0 ? product.price : 'Договорная'}</span> <label class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                         
//                             <div className='col-md-12'><p className="destext" >{product.descriptions}</p></div>
                        
//                             {/* <div className='col-md-7 col-6'>{Metro(product.metro)}</div> */}
                       
//                           <div className='row'>
//                             <div className='col-md-8'>
//                             {products_metro != null ? <>
//                                 {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
//                             <a >
//                                 {filteredMetro.color != 1 ? <><p style={{color:'gray'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'22px'}} /></p></> :
//                                  <>
//                                 <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
//                                 </>}
//                             </a>
                            
//                             ))}
                            
//                             </> : <>
                            
//                             </>}
//                             </div>
                            
//                             <div className='col-md-4 col-6'><label style={{ fontSize: '10px', float: 'right', marginRight: '-15px',color:'black' }} class="text-secondary meddate">{moment.tz(product.date1, "Europe/Moscow").fromNow()}</label></div>
                            
                           
//                           </div>
                           
                           
//                             <div className='row'>
//                             <div className='userimg4 col-md-6'>
//                             {product3 != null ? <>
//                                     <div>
//                             {product3.filter(product3 => product3.id == product.userid).map(filteredAvatar => (
//                                 <a href={'/user/' + filteredAvatar.id}>{filteredAvatar.avatar != null ? <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads + filteredAvatar.avatar}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads + 'user.jpg'}></img></>}</a>
//                             ))}
//                             </div>
//                                 </> : <></>}

//                             </div>
//                              <div  className='col-md-6  '>
//                              {favorited ?
//                                 <>
//                                     <a className='ml-5' > <label class="text-secondary postsms"> <i  type="primary" onClick={() => showModal(product.ad_id,product.userid)}  class="fa-solid fa-envelope  sms2"></i> <i onClick={() => delfavorites(product.ad_id)} class=" fa-solid fa-heart   heart2"></i></label></a>
//                                 </>
//                                 : <>
//                                     <a className='ml-5'>  <label class="text-secondary postsms"> <i type="primary" onClick={() => showModal(product.ad_id,product.userid)}  class="fa-solid fa-envelope  sms2"></i> <i onClick={() => addfavorites(product.ad_id)} class=" fa-solid fa-heart   heart"></i></label></a>
//                                 </>
//                                 }
//                             </div>

//                             </div>
                        
                           

//                         </div>


//                         <div  class="row d-block d-md-none ">

//                             <div className='col-md-12'><span class="card-title txt7">{product.price != 0 ? product.price : 'Договорная'}</span> <label class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
                         
//                             <div className='col-md-12'><p style={{fontSize:'12px'}} className="destext" > {product.descriptions}</p></div>
                        
//                             {/* <div className='col-md-7 col-6'><p style={{fontSize:'12px',marginTop:'15px'}} className='location_text'><i class="fa-sharp fa-solid fa-map-location-dot"></i> | </p></div> */}
                        
//                             {/* <div className='col-md-5 col-6'><label style={{ fontSize: '10px', float: 'right', marginRight: '-15px' }} class="text-secondary meddate">{moment(product.date1, "YYYY-MM-DD HH:mm:ss").fromNow()}</label></div> */}

//                             {/* <div className='col-md-6'><a href={'/user/' + product.userid}><img style={{ width: '30px', height: '30px', borderRadius: '50%',marginTop:'0px'}} src={product3[0].avatar != null ? uploads + product3[0].avatar : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></a></div> */}
                           
                           
                            
                            

//                         </div>
//                         <div className='d-block d-md-none'>
//                         {products_metro != null ? <>
//                                 {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
//                             <a >
//                                 {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'14px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'14px'}} /></p></> :
                                  
//                                  <>
//                                 <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></p>
//                                 </>}
//                             </a>
                            
//                             ))}
                            
//                             </> : <>
                            
//                             </>}
//                         </div >
                        
//                         <div className='row'>
//                         <div className='col-4 d-block d-md-none'>
//                         {product3 != null ? <>
//                                     <div>
//                             {product3.filter(product3 => product3.id == product.userid).map(filteredAvatar => (
//                                 <a href={'/user/' + filteredAvatar.id}>{filteredAvatar.avatar != null ? <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads + filteredAvatar.avatar}></img></> : <><img style={{ width: '30px', height: '30px', borderRadius: '50%', marginLeft: '-5px', marginTop: '-3px' }} src={uploads + 'user.jpg'}></img></>}</a>
//                             ))}
//                             </div>
//                                 </> : <></>}
         

//                                 </div>

//                                 <div className='col-8 d-block d-md-none'>
//                                 {favorited ?
//                                 <>
//                                     <a className='ml-5' > <label class="text-secondary postsms"> <i  type="primary" onClick={() => showModal(product.ad_id,product.userid)}  class="fa-solid fa-envelope  sms2"></i> <i onClick={() => delfavorites(product.ad_id)} class=" fa-solid fa-heart   heart2"></i></label></a>
//                                 </>
//                                 : <>
//                                     <a className='ml-5'>  <label class="text-secondary postsms"> <i type="primary" onClick={() => showModal(product.ad_id,product.userid)}  class="fa-solid fa-envelope  sms2"></i> <i onClick={() => addfavorites(product.ad_id)} class=" fa-solid fa-heart   heart"></i></label></a>
//                                 </>
//                                 }
//                                 </div>
//                         </div>
                        
                            
                                
                           
//                             </div>
                    
                    
//                        <div className='medbody2'>
//                         <div class="row d-block d-sm-none d-md-none ">

//                             <div style={{marginTop:'-10px'}} className='col-md-12 col-12'><span class="card-title " style={{fontFamily:'',fontSize:'16px'}}><b>{product.price != 0 ? product.price : 'Договорная'}</b></span> <label style={{fontSize:'8px'}} class="card-title ml-2 txt7cur "><b>{product.currency}</b></label></div>
//                             {/* {products_metro != null ? <>
//                                 {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
//                             <a  >
//                                 {filteredMetro.color != 1 ? <><a style={{color:'gray'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></a></> :
//                                  <>
//                                 <a style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='30px' height='30px' style={{borderRadius:'50%'}}></img></a>
//                                 </>}
//                             </a>
                            
//                             ))}
                            
//                             </> : <>
                            
//                             </>} */}
//                             <div className='col-md-12 col-12'><p style={{fontSize:'12px'}} className="destext" >{product.descriptions}</p></div>
                        
//                             {/* <div className='col-md-7 col-6'><p style={{fontSize:'12px',marginTop:'15px'}} className='location_text'><i class="fa-sharp fa-solid fa-map-location-dot"></i> | </p></div> */}
                        
//                             {/* <div className='col-md-5 col-6'><label style={{ fontSize: '10px', float: 'right', marginRight: '-15px' }} class="text-secondary meddate">{moment(product.date1, "YYYY-MM-DD HH:mm:ss").fromNow()}</label></div> */}

//                             {/* <div className='col-md-6 col-6'><a href={'/user/' + product.userid}><img style={{ width: '25px', height: '25px', borderRadius: '50%',marginTop:'-25px'}} src={product3[0].avatar != null ? uploads + product3[0].avatar : "https://cdn-icons-png.flaticon.com/512/149/149071.png"}></img></a></div> */}
                            
                         
//                             <div style={{width:'100%'}} className='row'>
//                             <div className='col-8'>
//                             {products_metro != null ? <>
//                                 {products_metro.filter(metro => metro.id_sql == product.metro).map(filteredMetro => (
//                             <a >
//                                 {filteredMetro.color != 1 ? <><p style={{color:'gray',fontSize:'10px'}} >{filteredMetro.Station} <MediumCircleFilled style={{color : '#' + filteredMetro.color, fontSize:'12px'}} /></p></> :
//                                  <>
//                                 <p style={{color:'gray'}} >{filteredMetro.Station} <img src={filteredMetro.image} width='20px' height='20px' style={{borderRadius:'50%'}}></img></p>
//                                 </>}
//                             </a>
                            
//                             ))}
                            
//                             </> : <>
                            
//                             </>}
//                             </div>
                            
//                             {/* {product4 != null || product4 != undefined || product4?.length > 0 ?
//                                 <>
//                                     <div className='userimg3 col-6'><a href={'/user/' + product.userid}><img style={{ width: '25px', height: '25px', borderRadius: '50%', }} src={product4[0].avatar != null ? uploads + product4[0].avatar : uploads + 'nouser.png'}></img></a></div>
//                                     <div className='userimg4 col-6'><a href={'/user/' + product.userid}><img style={{ width: '30px', height: '30px', borderRadius: '50%', }} src={product4[0].avatar != null ? uploads + product4[0].avatar : uploads + 'nouser.png'}></img></a></div>
//                                 </>
//                                 : <>
//                                 </>
//                             } */}
//                             <div style={{padding:0}} className='col-md-6 col-4'>
//                             {favorited ?
//                                 <>
//                                     <a className='ml-5' > <label class="text-secondary postsms"> <i  type="primary" onClick={() => showModal(product.ad_id,product.userid)}  class="fa-solid fa-envelope  sms2"></i> <i onClick={() => delfavorites(product.ad_id)} class=" fa-solid fa-heart   heart2"></i></label></a>
//                                 </>
//                                 : <>
//                                     <a className='ml-5'>  <label class="text-secondary postsms"> <i type="primary" onClick={() => showModal(product.ad_id,product.userid)}  class="fa-solid fa-envelope  sms2"></i> <i onClick={() => addfavorites(product.ad_id)} class=" fa-solid fa-heart   heart"></i></label></a>
//                                 </>
//                                 }
//                             </div>
                            
//                             </div>
                            
                            

//                             </div>
                            
//                     </div>
                    
//                 </div>

//             </>
//             : <>Loading</>
//         }





//     </>