import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLikes } from '../redux/actions/like_actions';
import Post2 from './post2';
import { API_URL } from '../config/config';
import React from 'react';
import { Button } from 'antd';
import { setProducts } from '../redux/actions/products_actions ';
import { useSelector } from 'react-redux';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Divider from '@mui/joy/Divider';
import Chip from '@mui/joy/Chip';

const Mainpost2 =()=>{
    const { products } = useSelector((state) => state.products);
    const dispatch = useDispatch();
    // const [products2, setProducts2] = useState(null);
    // const [products1,setProducts1] = useState(null);
    // const user_id = localStorage.getItem('token');
    const [limit, setLimit] = useState(16);
    const [usertoken, setUsertoken] = useState(null);
    const useridtoken = async () => {
        if(localStorage.getItem('token') != null){
            const post = {
                id: localStorage.getItem('token'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
                
                setUsertoken(data.data.users5[0].id);
                // Logged(data.data.users5[0].id);
                fetchProducts2(data.data.users5[0].id);
            }if(localStorage.getItem('g') != null){
                const post = {
                    id: localStorage.getItem('g'),
                };
                const data = await axios({
                    method: 'get',
                    params: post,
                    url: API_URL + '/users5'
                });
                
                if (data.data.status == 200) {
                    
                    setUsertoken(data.data.users5[0].id);
                    // Logged(data.data.users5[0].id);
                    fetchProducts2(data.data.users5[0].id);
                }
        }
            } 
      }
    const fetchProducts = async () => {
       const params={
            id : limit
        };
        const data = await axios({
            method:'get',
            params: params,
            url:API_URL+'/client1get'
        });
        if(data.data.status == 200){
            dispatch(setProducts(data.data.limit));
            setLimit( limit + 16 );
            localStorage.setItem('limit',limit);
            
        }else{
            
            
        }
    }
   
const [products_metro, setProducts_metro] = useState(null);
  const fetchProducts_metro = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getmetro'
    });
    if (data.data.status == 200) {
      setProducts_metro(data.data.getmetro);
      
    } else {
      
    }
  }
      const Metro = (metro) => {
        
        if (products_metro.length > 0 || products_metro != null && metro != null ) {
            const ad = products_metro.filter(i => i.id_sql == metro);
            
            if (ad.length > 0 || ad[0].Station != null){
                
                return <p>{ad[0].Station}</p>;
            } else {
                return <p>eror</p>;
            }
        }
    }
    const fetchProducts2 = async (usertoken) => {
        if(usertoken != null){
            const post = {
                user_id: usertoken,
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL+'/get_favorites'
            });
            if (data.data.status == 200) {
                dispatch(setLikes(data.data.favorites));
                
            } else {
                
                
            }
        }else{
            
            alert(usertoken);
        }
       
      }
      const limit_add = ()=>{
        fetchProducts();
      }
      

    useEffect(()=>{
        // localStorage.removeItem('limit');
        useridtoken();
        fetchProducts_metro();
        setTimeout(fetchProducts,500);
        
        // fetchProducts2();
        
    },[])
    const content = (
        <Box sx={{ fontSize: 'sm', color: 'text.tertiary' }}>
          {`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus id dignissim justo.
       Nulla ut facilisis ligula. Interdum et malesuada fames ac ante ipsum primis in faucibus.
       Sed malesuada lobortis pretium.`}
        </Box>
      );
    
    return(
        <>
        


<div class="row ml-1">
<div class="col-md-12 ">
    <h2 class="txt5 m-3">Рекомендации для вас</h2>
</div>
{products.length > 0 ?
    <>
    {products.map((item)=>
    <div class="col-6 col-md-3  mt-3 post2">
        <Post2 product={item}/>
        {/* <p>{item.metro != null ? <>{Metro(item.metro)}</>:<></>}</p> */}
    </div>
    )
    }
    
    {products.map((item)=>
    <div style={{padding:'3px',bottom:'20px'}} class="col-6 col-md-4  mt-2  post1">
        <Post2 product={item}/>
    </div>
    
    )
    }
    

    </>
    :<>Loading</>
}
<div className='row '>
<div className='col-md-4'></div>
    <div  className='col-md-4 col-12 d-none d-md-block mt-5'>
    <Button className='pokazat ml-3' onClick={limit_add} type="primary" block>
      Показать Ещё
    </Button>
    </div>
    <div style={{marginBottom:'100px'}} className='col-md-4 col-12 d-block d-md-none mt-5 '>
    <Button className='pokazat ml-3' onClick={limit_add} type="primary" block>
      Показать Ещё
    </Button>
    </div>
    <div className='col-md-4'></div>
</div>
    
    
    </div>
   
    
   
    </>
    );
    }
    
    
    export default Mainpost2;