import axios from "axios";
import { useState } from "react";

import {
  Modal
} from 'antd';
import { message, Upload } from 'antd';
// import React from 'react';
import { auth } from "../firebase/firebase-config";
import { useEffect } from "react";
import firebase from "firebase";
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from '@leecheuk/react-google-login'

import { API_URL, uploads } from '../config/config';
import Footer_menu from "./footer_menu";
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import * as React from 'react';




const clientId = '806199593777-9nsuk0sv1rroo6tkrmr5641u7e3td4fp.apps.googleusercontent.com';
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};
const TextMaskAdapter = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7 000 000 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const TextMaskAdapter2 = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+996 000 00 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const TextMaskAdapter3 = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="#############################"
      definitions={{
        '#': /[a-z,A-Z,0-9,(?=.*\&^%$#@!_+{}":?></|)]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const Registr = () => {

  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState(null);
  const [avatar, setAvatar] = useState();
  // const [numbers, setNumbers] = useState(null);
  const [code, setCode] = useState();
  const [uid, setUid] = useState();
  const [uid2, setUid2] = useState();
  const [final, setFinal] = useState();
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [city, setCity] = useState(null);
  const [style1, setStyle1] = useState(false);
  const [style2, setStyle2] = useState( true);
  const [product, setProduct] = useState(null);
  const [product2, setProduct2] = useState(null);
  const [logged, setLogged] = useState(false);
  const [google_id, setGoogle_id] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [numbers, setNumbers] = React.useState();
  const [kg, setKg] = useState(false);
  const [ru, setRu] = useState(false);
  const [gmail, setGmail] = useState(true);
  
  TextMaskAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  const [country, setCountry] = useState('3');
  const onChange = (value) => {
    setCountry(value);
    setNumbers(null);
    if(value == 3){
      setStyle2(true)
    }
  };

  const local = localStorage.getItem('token');
  const local2 = localStorage.getItem('g');
 

  const fetchProduct2 = async () => {
    const post = {
      id: numbers,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL+'/users4'
    });
    
    if (data.data.status == 200) {
      // alert('Такой номер существует');
      warning();
    } else {
      

    }
  }

  const fetchProduct = async () => {
    if(numbers != null){
      const post = {
        id:  numbers,
      };
      const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/users2'
      });
      
      if (data.data.status == 200) {
        setProduct2(data.data.users2);
        
        localStorage.setItem('token2', data.data.users2[0].id );
        localStorage.setItem('token', data.data.users2[0].uid );
        localStorage.setItem('text','');
        if(data.data.users2[0].uid != null){
          window.location.href = 'registr2/';
        }else{
          alert('uid == null');
        }
      } else {
        
  
      }
    }
   
  }
  const fetchStyle2 = async () => {
    setStyle2(true);
    
  }

  const fetchProduct3 = async (user) => {
    const post = {
      id: user.email,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL+'/users_login'
    });
    
    if (data.data.status == 200) {
      warning_modal3();
    } else {
      
      reg2(user);
    }
  }

  const fetchProduct4 = async (user) => {
    if(user.email != null){
      const post = {
        id: user.email,
      };
      const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/users_login'
      });
      
      if (data.data.status == 200) {
        setProduct2(data.data.users_login);
        
        localStorage.setItem('token2', data.data.users_login[0].id);
        localStorage.setItem('token', data.data.users_login[0].uid);
        localStorage.setItem('login', data.data.users_login[0].login);
        localStorage.setItem('text','');
        
        setLogged(true);
        window.location.href = '/registr2';
      } else {
        setLogged(false);
      }
    }
   

  }
  
  
  const registerWithEmail = () => { 
    // Регистрация с email и паролем
    firebase.auth().createUserWithEmailAndPassword(login, password)
      .then((userCredential) => {
        // Регистрация успешна
        const user = userCredential.user;
        console.log('User registered: ', user);
        setGoogle_id(user.uid);
        setUid(user.uid);
        setLogin(user.email);
  
        // Отправляем письмо для верификации email
        user.sendEmailVerification()
          .then(() => {
            // Вызов модала для уведомления
            warning_modal();
            console.log('Verification email sent!');
          })
          .catch((error) => {
            console.error('Error sending verification email:', error.message);
          });
      })
      .catch((error) => {
        // Ошибка при регистрации
        const errorCode = error.code;
        const errorMessage = error.message;
  
        // Обработка различных типов ошибок
        if (errorCode === 'auth/email-already-in-use') {
          console.error('Email уже используется.');
          // Здесь можно обработать ошибку, например, показать пользователю соответствующее сообщение
          error3();
        } else if (errorCode === 'auth/weak-password') {
          error4(); // Важно убедиться, что функция error4() определена и работает корректно
        } else {
          console.log(`Error: ${errorCode}, ${errorMessage}`);
        }
      });
  };
  



  const checkEmailVerification = () => {
    const user = firebase.auth().currentUser;
  
    if (user) {
      user.reload().then(() => {
        if (user.emailVerified) {
          console.log('Email is verified!');
          // warning_modal3();
          reg2(user);
          // Здесь можно выполнить действия для подтвержденного пользователя
        } else {
          console.log('Email is not verified yet.');
          warning_modal2();
          // Здесь можно предложить пользователю повторно отправить письмо для верификации
        }
      }).catch((error) => {
        console.error('Error reloading user data:', error.message);
      });
    } else {
      console.log('No user is signed in.');
    }
  };
  const warning_modal = () => {
    Modal.warning({
      title: 'Вам отправлен смс потверждением на почту !',
      content: 'Зайдите в почту и потвердите по ссылке! (проверьте спам папку!)',
      okText:'Потвердил',
      onOk() {
        checkEmailVerification();
      }
    });
  };
  const warning_modal2 = () => {
    Modal.warning({
      title: 'Вы не потвердили !',
      content: 'Зайдите в почту и потвердите по ссылке! (проверьте спам папку!)',
      okText:'Потвердил',
      onOk() {
        checkEmailVerification();
      }
    });
  };
  const warning_modal3 = () => {
    Modal.warning({
      title: 'Такой аккаунт уже зарегистрирован !',
      content: 'Восстановить пароль ?',
      okText:'Да, у меня есть доступ к этому почту',
      onOk() {
        window.location.href='/password_new'
      }
    });
  };



  


  const responseGoogle = async (response) => {
    localStorage.setItem('g', response.googleId);
    localStorage.setItem('token', response.profileObj.uid);
    localStorage.setItem('login', response.profileObj.email);
    localStorage.setItem('text','');
    setLogin(response.profileObj.email);
    setUid(response.profileObj.uid);
    setName(response.profileObj.givenName);
    setSurname(response.profileObj.familyName);
    setCity(response.profileObj.city);
    setGoogle_id(response.googleId);
    setGoogle_id(response.profileObj.googleId);
    
    const email = response.profileObj.email;
    const name = response.profileObj.name;
    const uid2 = response.profileObj.googleId;
    console.log(response);
    
    
    if (response != null) {
        setLogged(true);
        fetchProduct3(response.profileObj);
      // setAvatar(response.profileObj.imageUrl);
      
    }else{
      setLogged(false);
    }
  }
  
  const onFailure = (response) => {
    
  }

  const reg2 = async (user) => {
    const params = {
      'login' : user.email,
      'password' : password,
      'numbers' :numbers,
      'name' : user.givenName,
      'surname' : user.familyName,
      'city' : city,
      'uid' : user.uid,
      'google_id' :user.googleId,
    }
    
    const data = await axios({
      method: 'post',
      params: params,
      url: API_URL+'/registr',
      // headers: { "Content-Type": "multipart/form-data" }
    });
    
    if (data != null) {
      if (data.status == 200) {
        fetchProduct4(user);
        
      } else {
        alert('fetchproduct4 не запущен!')
      }
    }
  }



  const reg = async (uid) => {

    const params = {
      'login' : login,
      'password' : password,
      'numbers' : numbers,
      'name' : name,
      'surname' : surname,
      'city' : city,
      'uid' : uid,
      'google_id' :google_id,
    }
    const data = await axios({
      method: 'post',
      params: params,
      url: API_URL+'/registr_form',
    });
    
    
      if (data.data.status == 200) {
        success();
        setTimeout(() => {
          fetchProduct();
        }, 1000);
        
      } else {
        alert('fetchproduct не запущен!')
      }
  }
  useEffect(() => {
    
    // window.verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    // window.verify.render();
    // function start() {
    //   gapi.client.init({
    //     clientId: clientId,
    //     scope: ""
    //   })
    // };
    // gapi.load('client:auth2', start);
  }, []);


  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const success2 = () => {
    messageApi.open({
      type: 'success',
      content: 'Отправили смс потверждение на вашу почту!',
    });
  };

  const error4 = () => {
    messageApi.open({
      type: 'error',
      content: 'Слишком короткий пароль!',
    });
  };
  const error3 = () => {
    messageApi.open({
      type: 'error',
      content: 'Такой аккаунт существует!',
    });
  };
  const error2 = () => {
    messageApi.open({
      type: 'error',
      content: 'Проверьте правильности номера!',
    });
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Такой номер уже зарегистрирован!',
    });
  };

  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <>
{contextHolder}
<div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-100 to-teal-100 p-6">
    <div className="bg-white p-8 rounded-2xl shadow-lg w-full max-w-lg">
      <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">Регистрация</h2>
  
      <div className="mb-6">
      <GoogleLogin  
        className="w-full flex items-center justify-center border border-gray-300 text-gray-700 py-3 rounded-lg hover:bg-blue-50 transition duration-300 space-x-3 shadow-md"
        clientId={clientId}
        buttonText="Войти через Google"
        onSuccess={responseGoogle}
        // onClick={responseGoogle}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        isSignedIn={false}
      />
       
      </div>
  
      <div className="mt-8 text-center">
        <p className="text-sm text-gray-600">
          Или
        </p>
      </div>
  <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
            Email
          </label>
          <input 
            type="email" 
            id="email"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            placeholder="Введите ваш email"
          />
        </div>
        <div className="relative">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
            Пароль
          </label>
          <input 
            type={showPassword ? "text" : "password"} // Меняет тип поля в зависимости от состояния
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            placeholder="Введите пароль"
          />
          
        </div>

  
        <div className="flex items-center justify-between">
        <div className="flex items-center mt-5">
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)} // Переключает состояние показа пароля
            className=" right-4 top-1/2 transform -translate-y-1/2 text-blue-600"
          >Показать / скрыть
            {showPassword ? ( 
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye ">
            
                <path d="M1 12s3-7 11-7 11 7 11 7-3 7-11 7-11-7-11-7z"></path>
                <path d="M12 9c2.5 0 4.5 1.5 4.5 3s-2 3-4.5 3-4.5-1.5-4.5-3 2-3 4.5-3z"></path>
              </svg>
             
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-eye-off">
                <path d="M17 11c.15 1-.15 2-1 2s-1.85-1-1.7-2.15"></path>
                <path d="M22 12s-3-7-10-7S2 12 2 12s3 7 10 7 10-7 10-7z"></path>
              </svg>
            )}
          </button>
            {/* <input 
              type="checkbox" 
              id="remember" 
              className="h-5 w-5 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            /> */}
            
            {/* <label htmlFor="remember" className="ml-2 block text-sm text-gray-900">
              Запомнить меня
            </label> */}
          </div>
          <div>
            <a href="password_new" className="text-sm text-blue-600 hover:underline">
              Забыли пароль?
            </a>
          </div>
      
          
      
        </div>
  
        <div>
          <button 
            onClick={registerWithEmail}
            type="submit" 
            className="w-full mt-5 bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            Регистрация
          </button>
        </div>
  
  
      <div className="mt-8 text-center">
        <p className="text-sm text-gray-600">
          Вы уже зарегистрированы ? {' '}
          <a href="vhod" className="text-blue-600 hover:underline">
            Вход
          </a>
        </p>
      </div>
    </div>
  </div>
      <div  className='d-sm-block d-md-none d-lg-none d-xl-none '>
                <Footer_menu />
            </div>
    </>
  )
}
export default Registr;



// <body  className="body">
//       <div style={{paddingRight:'0',marginTop:'20px'}} className="container ">
//       <Nav />
//         <div class="row ">
    
//           <div style={{paddingRight:'0'}}  class="row ">
// 			<div  class="col-md-12 bg-white" >
// 				<div  class="row bg-white" >

//         <div className="col-md-4"></div>
// 					<div  class="col-md-4 mt-5 card ">
// 						<div  class="row ">
// 							<div class="col-md-12 text-center">
// 							<b class="a3">Birimdik</b>
//               <p><b>Регистрация</b></p>
// 							</div>
// 							<div class="col-md-12"  id="aa">
// 								<div class="row">
//                 <div class="col-md-3  col-4">
//                   <Select
//                       onChange={onChange}
//                       defaultValue={'3'}
//                       style={{
//                         marginLeft: '17px',
//                         width:'100%',
//                         height:'40px'
//                       }}
//                     >
//                       {kg != false ? <>
//                         <Option value={'2'}><img width='40px' height='40px'  src={uploads+'flag_kg.png'}></img>+996</Option>
//                       </>:<>
//                       </>}
//                       {ru != false ? <>
//                         <Option value={'1'}><img width='40px' height='40px'  src={uploads+'flag_rus.jpg'}></img>+7</Option>
//                       </>:<>
//                       </>}
//                       {gmail != false ? <>
//                         <Option value={'3'}><img width='40px' height='40px'  src={uploads+'gmail.png'}></img>@gmail</Option>
//                       </>:<>
//                       </>}
                      
                      
                      
//                     </Select>
//                   </div>
// 									<div class="col-md-8 col-8 ">
//                     {country == '1' ? 
//                     <><FormControl>
//                     <Input
//                       value={numbers}
//                       onChange={(event) => setNumbers(event.target.value)}
//                       placeholder="+7 *** *** ** **"
//                       slotProps={{ input: { component: TextMaskAdapter } }}
//                     />
//                   </FormControl>
//                     </>:
//                     <>
//                     </>}
//                     {country == '2' ? 
//                     <><FormControl>
//                     <Input
//                       value={numbers}
//                       onChange={(event) => setNumbers(event.target.value)}
//                       placeholder="+996 *** ** ** **"
//                       slotProps={{ input: { component: TextMaskAdapter2 } }}
//                     />
//                   </FormControl>
//                     </>:
//                     <>
//                     </>}
//                     {country == '3' ? 
//                     <><FormControl>
//                     <Input
//                       value={login}
//                       onChange={(event) => setLogin(event.target.value)}
//                       placeholder="********** @gmail.com"
//                       slotProps={{ input: { component: TextMaskAdapter3 } }}
//                     />
//                     {/* <Input
//                       style={{marginTop:'10px'}}
//                       value={password}
//                       onChange={(event) => setPassword(event.target.value)}
//                       placeholder="Пароль"
//                       slotProps={{ input: { component: TextMaskAdapter3 } }}
//                     /> */}
//                     <button style={{marginTop:'10px'}} class="form-control btn-primary" onClick={registerWithEmailPassword}>ОК</button>
//                   </FormControl>
//                     </>:
//                     <>
//                     </>}
                  
// 										{/* <Input   onChange={(e) => { setNumbers(e.target.value) }} type="text" id="numbers"  placeholder="Номер телефона" class="form-control inp" name=""/> */}
// 								</div>
// 									<div class="col-md-1"></div>
								
// 								<div class="col-md-1 col-1"></div>
// 								<div class="col-md-12 col-10 mt-2">
//                 {style2 ?
//                  <>
//                  </>: 
//                 <>
//                 {/* <div class="g-recaptcha" data-sitekey="ВАШ_КЛЮЧ"></div> */}
//                 {/* <div class="g-recaptcha"  style={{float:'right'}}  id="recaptcha-container"></div> */}
//                 </>}
                
                
// 									{/* <input onChange={(e) =>{setPassword(e.target.value)}} type="password" id="password" placeholder="Пароль" class="form-control" name=""/> */}
// 								</div>

// 								<div class="col-md-1"></div>
// 								<div class="col-md-3 col-3"></div>
// 								<div class="col-md-8 col-6 mt-2">
//                 {style2 ?
//                  <>
//                  </>:
//                 <>
//                 <button class="form-control btn-primary" onClick={fetchProduct2}>ОК</button>
//                 </>}
								
// 								</div>

//                 {style1 ?
//                       <>
//                       <div className="col-md-3 col-4"></div>
//                       <div className="col-md-6 col-4 mt-2">
                        
//                       <Input placeholder="Код из СМС" onChange={(e) => { setCode(e.target.value) }} />
//                       </div>
//                       <div className="col-md-4 col-4"></div>
//                       <div className="col-md-4 col-4"></div>
//                         <div className="col-md-4 col-4 mt-3">
//                           <Form.Item >
//                           <div class="col-md-12 " id="a1"></div>
//                             <Button style={{width:'100%'}} type="secondary" onClick={confirmOtp} >OK</Button>
//                           </Form.Item>
                          
//                         </div>
//                       </>
//                       : <>
//                       </>
//                     }
//                     	<div class="col-md-2"></div>
// 								<div class="col-md-10 mt-2 text-center">
//                   <GoogleLogin
                      
//                       clientId={clientId}
//                       buttonText="Войти через Google"
//                       onSuccess={responseGoogle}
//                       // onClick={responseGoogle}
//                       onFailure={onFailure}
//                       cookiePolicy={'single_host_origin'}
//                       isSignedIn={false}
//                     />
// 								</div>
// 								<div class="col-md-2"></div>

// 								<div class="col-md-2"></div>
// 								<div class="col-md-8 mt-3 ">
//                   <a style={{float:'right'}} href="password_new">Забыли пароль?</a>
// 								</div>
// 								<div class="col-md-2"></div>
								
// 								<div class="col-md-12 ins2">
// 						<label class="text-center mt-2">У вас есть аккаунт ?</label>
// 						<a href="vhod"><b> Вход</b></a>
// 					</div>
          
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 					<div class="col-md-4"></div>
//           <div style={{ marginTop: '100px' }}>
//             <Footer />
//           </div>
// 			</div>
// 			</div>

			
// 		</div>
       

          

        

//         </div>
//       </div>
//       </body>