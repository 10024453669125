import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Nav from './navbar';
import Post2 from './post2';
import { API_URL } from '../config/config';
import { Button, Tabs } from 'antd';
import Footer_menu from './footer_menu';
import moment from 'moment';

const User = () => {
  const { id: userid } = useParams();
  const [neaktiv, setNeaktiv] = useState([]);
  const [products, setProducts] = useState([]);
  const [productuser, setProductuser] = useState(null);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching user data...");
        await fetchProductuser();
        await fetchProducts(userid);
        await fetchNeaktiv(userid);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, [userid]);

  const fetchProductuser = async () => {
    try {
      const { data } = await axios.get(`${API_URL}/users1`, { params: { id: userid } });
      if (data.status === 200) {
        setProductuser(data.users1);
        console.log("User data fetched:", data.users1);
      }
    } catch (error) {
      console.error("Error fetching product user:", error);
    }
  };

  const fetchProducts = async (userid) => {
    try {
      const { data } = await axios.get(`${API_URL}/my_ad`, { params: { id: userid, limit } });
      if (data.status === 200) {
        setProducts(data.my_ad);
        console.log("Active products fetched:", data.my_ad);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchNeaktiv = async (userid) => {
    try {
      const { data } = await axios.get(`${API_URL}/neaktiv`, { params: { user_id: userid } });
      if (data.status === 200) {
        setNeaktiv(data.neaktiv);
        console.log("Inactive products fetched:", data.neaktiv);
      }
    } catch (error) {
      console.error("Error fetching inactive products:", error);
    }
  };

  if (!productuser) {
    return <p>Loading user data...</p>; // Показать загрузочный текст
  }

  return (
    <>
      <Nav />
      <div className="container">
        <div className="row mt-4">
          <div className="col-md-1">
            <a>
              <img
                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                src={
                  productuser[0]?.avatar
                    ? `https://birimdik.kg/birimdik/uploads/${productuser[0]?.avatar}`
                    : 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
                }
                alt="User Avatar"
              />
            </a>
          </div>
          <div className="col-md-2">
            <h4 style={{ fontFamily: 'serif' }}>
              <b>{productuser[0]?.name}</b>
            </h4>
            <p style={{ color: 'grey', marginTop: '-15px', fontSize: '16px' }}>
              Дата регистрации: {moment(productuser[0]?.date1).fromNow()}
            </p>
          </div>

          <div className="col-md-12">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Объявления" key="1">
                <Tabs defaultActiveKey="3">
                  <Tabs.TabPane tab="Активные" key="8">
                    {products.length > 0 ? (
                      <div className="row">
                        {products.map((item) => (
                          <div className="col-6 col-md-3 mt-3" key={item.id}>
                            <Post2 product={item} />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Нет активных объявлений...</p>
                    )}
                    <Button onClick={() => fetchProducts(userid)} type="primary" block>
                      Показать ещё
                    </Button>
                  </Tabs.TabPane>

                  <Tabs.TabPane tab="Неактивные" key="9">
                    {neaktiv.length > 0 ? (
                      <div className="row">
                        {neaktiv.map((item) => (
                          <div
                            className="col-6 col-md-3 mt-3"
                            key={item.id}
                            style={{ pointerEvents: 'none', opacity: '0.5' }}
                          >
                            <Post2 product={item} />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p>Нет неактивных объявлений...</p>
                    )}
                  </Tabs.TabPane>
                </Tabs>
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      <Footer_menu />
    </>
  );
};

export default User;
