import axios from "axios";
import { LoadingOutlined, MediumCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, message, Spin, Upload } from 'antd';
import React, { useState } from 'react';
import { Select, Checkbox, TreeSelect, Space } from 'antd';
// import { Input } from 'antd';
import Nav from "./navbar";
import Footer from "./footer";
import { useEffect } from "react";
import { Option } from "antd/lib/mentions";
import { useParams } from "react-router-dom";
import { Cascader } from 'antd';
import DragAndDropUploader from "./Drag_And_Drop_Uploader";
import { API_URL, uploads } from '../config/config';
import Footer_menu from "./footer_menu";
import Textarea from '@mui/joy/Textarea';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import FormControl from '@mui/joy/FormControl';
import { styled } from '@mui/joy/styles';
import Input from '@mui/joy/Input';
import InputEmojiWithRef from "react-input-emoji";
const TextMaskAdapter = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+7 000 000 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const TextMaskAdapter2 = React.forwardRef(function TextMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="+996 000 00 00 00"
      definitions={{
        '#': /[1-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      // overwrite
    />
  );
});
const StyledInput = styled('input')({
  border: 'none', // remove the native input border
  minWidth: 0, // remove the native input width
  outline: 0, // remove the native input outline
  padding: 0, // remove the native input padding
  paddingTop: '1em',
  flex: 1,
  color: 'inherit',
  backgroundColor: 'transparent',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  textOverflow: 'ellipsis',
  '&::placeholder': {
    opacity: 0,
    transition: '0.1s ease-out',
  },
  '&:focus::placeholder': {
    opacity: 1,
  },
  '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label': {
    top: '0.5rem',
    fontSize: '0.75rem',
  },
  '&:focus ~ label': {
    color: 'var(--Input-focusedHighlight)',
  },
  '&:-webkit-autofill': {
    alignSelf: 'stretch', // to fill the height of the root slot
  },
  '&:-webkit-autofill:not(* + &)': {
    marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
    paddingInlineStart: 'var(--Input-paddingInline)',
    borderTopLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    borderBottomLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
  },
});
const StyledLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  lineHeight: 1,
  top: 'calc((var(--Input-minHeight) - 1em) / 2)',
  color: theme.vars.palette.text.tertiary,
  fontWeight: theme.vars.fontWeight.md,
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
}));
const InnerInput = React.forwardRef(function InnerInput(props, ref) {
  const id = React.useId();
  return (
    <React.Fragment>
      <StyledInput {...props} ref={ref} id={id} />
      <StyledLabel htmlFor={id}>Label</StyledLabel>
    </React.Fragment>
  );
});

const onChange = (value) => {
  
};
const { TextArea } = Input;

const Transport = () => {
  
  TextMaskAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  const [country, setCountry] = useState('1');
  const onChange0 = (value) => {
    setCountry(value);
    setNumbers(null);
  };


  const [custom, setCustom] = useState(false);
  const [custom2, setCustom2] = useState(false);
  const [custom3, setCustom3] = useState(false);
  const [custom4, setCustom4] = useState(false);
  const [custom5, setCustom5] = useState(false);
  const [custom6, setCustom6] = useState(false);
  const [custom7, setCustom7] = useState(false);
  const [custom8, setCustom8] = useState(false);
  const [custom9, setCustom9] = useState(false);
  const [custom10, setCustom10] = useState(false);
  const [subCategories, setSubcategories] = useState(2);
  const [subCategories2, setSubcategories2] = useState();
  const [subCategories3, setSubcategories3] = useState();
  const [subCategories4, setSubcategories4] = useState();
  const [subCategories5, setSubcategories5] = useState();
  const [subCategories6, setSubcategories6] = useState();
  const [subCategories7, setSubcategories7] = useState();
  const [year, setYear] = useState();
  const [yearr, setYearr] = useState();
  const [year3, setYear3] = useState();
  // const userid = localStorage.getItem('token');
  const [usertoken, setUsertoken] = useState(localStorage.getItem('token2'));
  const [useruid, setUseruid] = useState(localStorage.getItem('token'));
  const useridtoken = async () => {
    if(localStorage.getItem('token') != null){
        const post = {
            id: localStorage.getItem('token'),
        };
        const data = await axios({
            method: 'get',
            params: post,
            url: API_URL + '/users5'
        });
        if (data.data.status == 200) {
            setUsertoken(data.data.users5[0].id);    
        }if(localStorage.getItem('g') != null){
            const post = {
                id: localStorage.getItem('g'),
            };
            const data = await axios({
                method: 'get',
                params: post,
                url: API_URL + '/users5'
            });
            
            if (data.data.status == 200) {
              setUsertoken(data.data.users5[0].id);    
            }
    }
        } 
  }
  const params = useParams();
  const [id, setId] = useState(params.id);

  const [images, setImages] = useState(null);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [image6, setImage6] = useState(null);
  const [files, setFiles] = useState([]);
  const [mark, setMark] = useState();
  const [name, setName] = useState();
  const [location, setLocation] = useState();
  const [descriptions, setDescriptions] = useState();
  const [categories, setCategories] = useState(2);
  const [price, setPrice] = useState(0);
  const [numbers, setNumbers] = useState();
  const [currency, setCurrency] = useState("RUB");
  const [podcategories, setPodcategories] = useState(18);
  const [models, setModels] = useState();
  const [buy_sell, setBuy_sell] = useState();
  const [recommend, setRecommend] = useState();
  const [city, setCity] = useState();
  const [generation, setGeneration] = useState();
  const [get_currency, setGet_currency] = useState();
  const [atributes, setAtributes] = useState([]);
  const [serie, setSerie] = useState();
  const [modification, setModification] = useState();
  const [equipment, setEquipment] = useState();
  const [metro, setMetro] = useState();
  
  const { Option } = Select;
  const handleChange = (value) => {
    setAtributes(value);
    
    
  };

  




  const addpost = async () => {
    loading();
    const form = new FormData();

    form.append('location', location);
    // form.append('images', files[0]);
    // form.append('image1', files[1]);
    // form.append('image2', files[2]);
    // form.append('image3', files[3]);
    // form.append('image4', files[4]);
    // form.append('image5', files[5]);
    // form.append('image6', files[6]);
    form.append('price', price);
    form.append('numbers', numbers);
    form.append('descriptions', descriptions);
    form.append('categories', categories);
    form.append('currency', currency);
    form.append('userid', usertoken);
    form.append('city', city);
    form.append('podcategories', parseInt(podcategories));
    form.append('models', models);
    form.append('buy_sell', buy_sell);
    form.append('custom_atribute', JSON.stringify(atributes));
    form.append('mark', mark);
    form.append('generation', generation);
    form.append('serie', serie);
    form.append('modification', modification);
    form.append('equipment', equipment);
    form.append('year', yearr);
    form.append('metro', metro);
    
    const data = await axios({
      method: 'post',
      data: form,
      url: API_URL + '/client1',
      headers: { "Content-Type": "multipart/form-data" }
    });
  
    if (data != null) {
      if (data.status == 200) {
        success();
        get_my_ad();
      } else {
        error2();
      }
    }
  }
  const [my_ad, setMy_ad] = useState(null);
  const  get_my_ad = async () => {
    const post = {
        id: usertoken,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/my_ad_upload'
    });
    
    if (data.data.status == 200) {
        setMy_ad(data.data.my_ad[0].id);
        
        // 
        window.location.href='/upload_image/'+ data.data.my_ad[0].id
    }if(useruid != null){
      const post = {
        id: useruid,
    };
    const data = await axios({
        method: 'get',
        params: post,
        url: API_URL+'/my_ad_upload'
    });
    
    if (data.data.status == 200) {
        setMy_ad(data.data.my_ad[0].id);
        
        // 
        window.location.href='/upload_image/'+ data.data.my_ad[0].id
    }
    } else {
      warning2();
    } 
}
  const [products, setProducts] = useState(null);
  const fetchProducts = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getmetro'
    });
    if (data.data.status == 200) {
      setProducts(data.data.getmetro);
      
    } else {
      
    }
  }

  const [products2, setProducts2] = useState(null);
  const fetchProducts2 = async () => {
    const post = {
      id: 2,
    };
    const data = await axios({
      method: 'get',
      params: post,
      url: API_URL + '/getcategory'
    });
    if (data.data.status == 200) {
      setCategories(2);
      setProducts2(data.data.getcategory);
      filter();
      
    } else {
      
      
    }
  }
  const filter = () => {
    if (products > 0 || products != null) {
      const ad = products.filter(i => i.parent_id == 0);
      
      if (ad.length > 0 && ad[0].city != null) {
        
        return ad[0].city;
      } else {
        return 'error';
      }
    }
  }

  const fetchCurrency = async () => {
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcurrency',
    })
    
    if (data.data.status == 200) {
      setGet_currency(data.data.getcurrency);
    } else {

    }
  }
// auto 
  const onChange = async (value) => {
    
    setPodcategories(value);
    fetchCategory(value);
    setSubcategories(null);
    setSubcategories2(null);
    setSubcategories3(null);
    setSubcategories4(null);
    setSubcategories6(null);
    setSubcategories7(null);
    setYear(null);
    setCustom(false);
    setCustom2(false);
    setCustom3(false);
    setCustom4(false);
    setCustom5(false);
    setCustom6(false);
    setCustom7(false);
    setCustom8(false);
    setCustom9(false);
    if(value == 19 || 20 || 21 || 22 ||23 ||24){
      setCustom(true);
      setCustom10(true);
    }if(value == 18){
      setCustom10(false);
    }
  };
  const onChange1 = async (value) => {
    
    setBuy_sell(value);
  };

  const fetchCategory = async (id) => {
    if (id == 18 || id ==19){
      const data = await axios({
        method: 'get',
        url: API_URL + '/getcategory4',
      })
      
      if (data.data.status == 200){
        setCustom(true);
        setCustom2(false);
        setSubcategories(data.data.getcategory4);
      } else{
        setCustom(false);
      }
    }
      const params = {
        id: 1800
      }
      const data = await axios({
        method: 'get',
        params: params,
        url: API_URL + '/getcategory',
      })
      
      if (data.data.status == 200){
        setSubcategories5(data.data.getcategory);
      }
  }
  // mark 
  const onChange2 = async (value) => {
    
    setCustom7(false);
    setCustom3(false);
    setCustom4(false);
    setCustom5(false);
    setCustom6(false);
    setMark(value);
    fetchCategory2(value);
    setSubcategories2(null);
    setSubcategories3(null);
    setSubcategories4(null);
    setSubcategories6(null);
    setSubcategories7(null);
    setYear(null);
    
  };
  
  const fetchCategory2 = async (value) => { 
    setCustom2(true);
    const params = {
      id: value
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory5',
      params: params
    })
    
    if (data.data.status == 200) {
      setCustom2(true);
      setCustom3(false);
      setSubcategories2(data.data.getcategory5);
    } else {
      setCustom2(false);
  }
  }
  // model 
  const onChange3 = async (value) => {
  
  setModels(value);
  setCustom3(false);
  setCustom4(false);
  setCustom5(false);
  setCustom6(false);
  setSubcategories3(null);
  setYear(null);
  setSubcategories4(null);
  setSubcategories6(null);
  setSubcategories7(null);
  fetchCategory3(value);
  
};
  const fetchCategory3 = async (id) => {
    const params = {
      id: id
    }
      const data = await axios({
        method: 'get',
        url: API_URL + '/getcategory6',
        params: params
      });
      
      if (data.data.status == 200) {
        setCustom3(true);
        setCustom4(false);
        setSubcategories3(data.data.getcategory6);
      } else {
        setCustom3(false);
      }
  }
  const fetchCategory8 = async (id) => {
    const params = {
      id: id
    }
      const data = await axios({
        method: 'get',
        url: API_URL + '/getyear',
        params: params
      });
      
      if (data.data.status == 200) {
        setYear(data.data.getyear);
      } else {
      }
  }
  const fetchCategory10 = async () => {
      const data = await axios({
        method: 'get',
        url: API_URL + '/year3',
      });
      
      if (data.data.status == 200) {
        setYear3(data.data.year3);
      } else {
      }
  }
  // generation 
  const onChange4 = async (value) => {
    setYear(null);
    setSubcategories4(null);
    setSubcategories6(null);
    setSubcategories7(null);
    setCustom5(false);
    setCustom4(false);
    
    setGeneration(value);
    fetchCategory4(value);
    fetchCategory8(value);
  };
  const fetchCategory4 = async (id) => {
    const params = {
      id: id
    }
    
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory7',
      params: params
    })
    
    if (data.data.status == 200) {
      setCustom4(true);
      setSubcategories4(data.data.getcategory7);
    } else {
      setCustom4(false);
    }

  }
  // serie 
  const onChange5 = async (value) => {
    
    setSubcategories6(null);
    setSubcategories7(null);
    setSerie(value);
    setCustom5(true);
    setCustom6(false);
    fetchCategory6(value);
  };
  const fetchCategory6 = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory8',
      params: params
    });
    
    if (data.data.status == 200) {
      setSubcategories6(data.data.getcategory8);
    } else {
    }
  }
  // modification 
  const onChange6 = async (value) => {
    
    setSubcategories7(null);
    setModification(value);
    setCustom6(true)
    fetchCategory7(value);
  };
  const fetchCategory7 = async (id) => {
    const params = {
      id: id
    }
    const data = await axios({
      method: 'get',
      url: API_URL + '/getcategory9',
      params: params
    });
    
    if (data.data.status == 200) {
      setCustom5(true);
      setSubcategories7(data.data.getcategory9);
    } else {
    }
  }
  // equipment
  const onChange7 = async (value) => {
    
    setEquipment(value);
  };
  // year 
  const onChange8 = async (value) => {
    
  
    setYearr(value);
  };
  const onChange9 = async (value) => {
    
    setName(value);
  };

  const [messageApi, contextHolder] = message.useMessage();
  const loading = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
  };
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Успешно!',
    });
  };
  const success2 = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
    setTimeout(success,3000)
  };
  const error2 = () => {
    messageApi.open({
      content:  <Spin /> ,
    });
    setTimeout(warning,3000)
  };
  const warning = () => {
    messageApi.open({
      type: 'warning',
      content: 'Ошибка!',
    });
}
const warning2 = () => {
  messageApi.open({
    type: 'warning',
    content: 'Ошибка, Переавторизуйтесь!',
  });
}

  useEffect(() => {
    fetchCategory(18);
    useridtoken();
    fetchProducts();
    fetchProducts2();
    fetchCurrency();
    fetchCategory10();
    filter();
  }, [])
  return (
    <>
    {contextHolder}
      <Nav />
      <div style={{marginTop:'50px'}} className="container">
        <div class="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 card">
            <div class="row">
              <div className='col-md-12'>
                <h5>Разместите БЕСПЛАТНОЕ обьявление</h5>
              </div>
              <div className='col-md-12'>
               
                <div className="row">
                  <div className="col-md-3">

                  </div>
                </div >

              </div>
              {/* <div className="col-md-3 mt-3 d-none">
                <Cascader options={options} onChange={onChange} placeholder="Please select" />
              </div> */}


              {products2 != null || products2 != undefined || products2?.length > 0 ?
                <>
                  <div className="col-md-3 mt-3">
                    {/* auto */}
                    <Select onChange={onChange} defaultValue={products2[0].name} style={{ width: '100%' }}>
                      {products2.map((products) =>
                        <Option value={products.id}>{products.name}</Option>
                      )
                      }
                    </Select>
                  </div>
                  </>
                : <>Loading</>
              }
                {subCategories5?.length > 0 ?
                          <>
                          <div className="col-md-3 mt-3">
                            {/* kuplyuprodayu */}
                            <Select onChange={onChange1} defaultValue={subCategories5[0].name} style={{ width: '100%' }}>
                              {
                                subCategories5.map((products) =>
                                  <Option value={products.id}>{products.name}</Option>

                                )
                              }

                            </Select>
                            </div>
                          </> : <></>
                        }
               {custom ?
                    <>
                      <div className="col-md-3 mt-3">

                        {subCategories?.length > 0 ?
                          <>
                            {/* mark */}
                             <Select
                                               onChange={onChange2}
                                             showSearch
                                             style={{
                                             width: 200, 
                                           }}
                                           placeholder="Все марки"
                                           optionFilterProp="children"
                                           > 
                                           <Option value={null}>Все</Option>
                                         {subCategories.map((products) =>
                                           <Option
                                           filterOption={(input, products) => (products?.label ?? '').includes(input)}
                                           filterSort={(productsA, productsB) =>
                                             (productsA?.label ?? '').toLowerCase().localeCompare((productsB?.label ?? '').toLowerCase())
                                           }
                                           value={products.id_car_mark}>{products.name}
                                           </Option>
                                         )
                                         }
                                         </Select>
                          </> : <></>
                        }
                      </div>
                      
                    </>
                    : <></>
                  }
                       
                        {/* {custom10 ? <>
                        <div className="col-12 col-md-3 mt-3">
                          <Input id="models" name="models" onChange={(e) => { setModels(e.target.value) }} rows={1} placeholder="Модель" />
                        </div>
                        
                          </>
                          :<>
                          
                          </>
                          } */}
                       
                      
                 
                  {custom2 ?
                    <>
                      <div className="col-md-3 mt-3">

                        {subCategories2?.length > 0 ?
                          <>
                            {/* model */}
                             <Select
                                                onChange={onChange3}
                                              showSearch
                                              style={{
                                              width: 200, 
                                            }}
                                            placeholder="Все модели"
                                            optionFilterProp="children"
                                            > 
                                            <Option value={null}>Все</Option>
                                          {subCategories2.map((products) =>
                                            <Option
                                            filterOption={(input, products) => (products?.label ?? '').includes(input)}
                                            filterSort={(productsA, productsB) =>
                                              (productsA?.label ?? '').toLowerCase().localeCompare((productsB?.label ?? '').toLowerCase())
                                            }
                                            value={products.id_car_model}>{products.name}
                                            </Option>
                                          )
                                          }
                                          </Select>

                          </> : <></>
                        }
                      </div>

                    </>
                    : <></>
                    
                  } 
                               
                  {custom3 ?
                    <>
                      {subCategories3?.length > 0 ?
                        <><div className="col-md-5 mt-3">
                          {/* generation */}
                          <Select onChange={onChange4} defaultValue={subCategories3[0].name} style={{ width: '100%',fontSize:'12px'}}>
                            {
                              subCategories3.map((products) =>
                                <Option style={{ width: '100%',fontSize:'12px'}} value={products.id_car_generation}>{products.name} {products.year_begin}-{products.year_end}</Option>

                              )
                            }

                          </Select>
                        </div>
                        </> : <></>
                      }
                    </>
                    : <></>
                  }
                  {year ?.length > 0 ?
                          <>
                          <div className="col-md-3 mt-3">
                            {/* year  */}
                            {/* <Select onChange={onChange8} defaultValue={year[0].year} style={{ width: '100%' }}>
                              {
                                year.map((products) =>
                                  <Option value={products.year}>{products.year}</Option>

                                )
                              }

                            </Select> */}
                          
                            {/* year  */}
                            <Select onChange={onChange8} defaultValue={year3[0].year} style={{ width: '100%' }}>
                              {
                                year3.map((products) =>
                                  <Option value={products.year}>{products.year}</Option>

                                )
                              }

                            </Select>
                            </div>
                         
                          </> : <></>
                        }
                  {custom4 ?
                    <>
                      <div className="col-md-3 mt-3">
                        {subCategories4?.length > 0 ?
                          <>
                            {/* serie */}
                            <Select onChange={onChange5} defaultValue={subCategories4[0].name} style={{ width: '100%' }}>
                              {
                                subCategories4.map((products) =>
                                  <Option value={products.id_car_serie}>{products.name}</Option>

                                )
                              }

                            </Select>
                          </> : <></>
                        }
                      </div>
                    </> :
                    <></>
                  }
                  {custom5 ?
                    <>
                   {/* modification */}
                      <div className="col-md-5 mt-3">
                        {subCategories6?.length > 0 ?
                          <>
                            <Select onChange={onChange6} defaultValue={subCategories6[0].name} style={{ width: '100%',fontSize:'12px'}}>
                              {
                                subCategories6.map((products) =>
                                  <Option style={{ width: '100%',fontSize:'12px'}} value={products.id_car_modification}>{products.name} {products.start_production_year}-{products.end_production_year}</Option>
                                )
                              }

                            </Select>
                          </> : <>
                          </>
                        }
                      </div>
                    </> :
                    <></>
                  }
                  {custom6 ?
                    <>
                    {/* equipment */}
                       
                        {subCategories7?.length > 0 ?
                          <><div className="col-md-3 mt-3">
                            <Select onChange={onChange7} defaultValue={subCategories7[0].name} style={{ width: '100%' }}>
                              {
                                subCategories7.map((products) =>
                                  <Option value={products.id_car_equipment}>{products.name}</Option>
                                )
                              }

                            </Select> </div>
                          </> : <>
                          </>
                        }
                     
                    </> :
                    <></>
                  }
              <div className="col-md-auto mt-3">
              {products != null || products != undefined || products ?.length > 0 ?<>
                  <Select
                    onChange={setMetro}
                  showSearch
                  style={{
                  width: 250, 
                }}
                
                placeholder="Метро"
                optionFilterProp="children"
                > 
                <Option value={null}>Все</Option>
              {products.map((products) =>
                <Option
                filterOption={(input, products) => (products?.label ?? '').includes(input)}
                filterSort={(productsA, productsB) =>
                  (productsA?.label ?? '').toLowerCase().localeCompare((productsB?.label ?? '').toLowerCase())
                }
                value={products.id_sql}>{products.Station}
                {products.color != 1 ? <> <MediumCircleFilled style={{color : '#' + products.color, fontSize:'22px'}} /> </> :
                                     <>
                                     <img src={products.image} width='15px' height='15px' style={{borderRadius:'50%'}}></img>
                                    </>}
                </Option>
              )
              }
              </Select>
        </>
        :<>Loading</>
        }
              </div>
              
              <div className='col-md-3 mt-3'>
                <Input id="price" type="number" name="price" defaultValue={0} onChange={(e) => { setPrice(e.target.value) }} rows={1} placeholder="Цена" />
              </div>
              <div className='col-md-3 mt-3'>
                {get_currency != null || get_currency != undefined || get_currency?.length > 0 ?
                  <><Select onChange={setCurrency} defaultValue={get_currency[1].currency} style={{ width: '100%' }}>
                    {get_currency.map((products) =>
                      <Option value={products.currency}>{products.currency}</Option>
                    )
                    }
                  </Select>
                  </>
                  : <>Loading</>
                }
              </div>
                 
              <div className='col-md-auto mt-2'>
              <div class="row">
									<div class="col-md-3  col-4">
                  <Select
                      onChange={onChange0}
                      defaultValue={'1'}
                      style={{
                        marginLeft: '17px',
                        width:'100%',
                        height:'40px'
                      }}
                    >
                      <Option value={'2'}><img width='40px' height='40px'  src={uploads+'flag_kg.png'}></img>+996</Option>
                      <Option value={'1'}><img width='40px' height='40px'  src={uploads+'flag_rus.jpg'}></img>+7</Option>
                    </Select>
                  </div>
									<div class="col-md-8 col-8 ">
                    {country == '1' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+7 *** *** ** **"
                      slotProps={{ input: { component: TextMaskAdapter } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                    {country == '2' ? 
                    <><FormControl>
                    <Input
                      value={numbers}
                      onChange={(event) => setNumbers(event.target.value)}
                      placeholder="+996 *** ** ** **"
                      slotProps={{ input: { component: TextMaskAdapter2 } }}
                    />
                  </FormControl>
                    </>:
                    <>
                    </>}
                   
                  </div>
                </div>
            </div>
              <h5>Описание</h5>
              <InputEmojiWithRef
                
                maxLength={300}
                onChange={setDescriptions}
                // onChange={(e) => { setDescriptions(e.target.value) }}
                placeholder="Описание"
                name="descriptions"
                id="descriptions"
                  color="neutral"
                  minRows={2}
                  variant="outlined"
                />
              {/* <p><b>Загрузите фото</b>(до 7)</p>
                <div  className="col-md-12">
                  <DragAndDropUploader
                    className="mb-3 p-2"
                    style={{ width: '100%', maxHeight: '150px' }}
                    onChange={(file) => {
                      setFiles([...files, file]);
                    }}
                    onRemove={(f) => {
                      const index = files.indexOf(f);
                      if (index !== -1) {
                        const f = files.splice(index, 1);
                        setFiles(f);
                      }
                    }}
                  />
                </div> */}

              <div className='col-md-12 mt-2'>
                <button onClick={addpost} className='btn btn-primary'>
                  Опубликовать
                </button>
              </div>

            </div>
          </div>

        </div>
      </div>
      <Footer />

      <div  className='d-sm-block d-md-none d-lg-none d-xl-none '>
                <Footer_menu />
            </div>
    </>
  )
}
export default Transport;

// {custom4 ?
//   <>
  
//     <div className="col-md-3 mt-3">
//       {subCategories4?.length > 0 ?
//         <>
//           {/* room */}
//           {/* <Select onChange={onChange5} defaultValue={subCategories4[0].name} style={{ width: '100%' }}> */}
//           <Select
//             mode="multiple"
//             style={{
//               width: '100%',
//             }}
//             defaultValue={subCategories4[0].name}
//             onChange={handleChange}
//             optionLabelProp="label"
//           >
//             {
//               subCategories4.map((products) =>
//                 <Option value={products.name} >

//                   {products.name}

//                 </Option>

//               )
//             }
//           </Select>
//           {/* </Select> */}
//         </> : <></>
//       }


//     </div>
//   </> :
//   <></>
// }